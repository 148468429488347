import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import "../assets/css/global_styles.css";
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import BlogsListComponent from "./BlogsListComponent";
import "../assets/css/individual_blogs.css";

function IndividualBlogsComponent() {
  const { blogId } = useParams();
  const [currentBlogId, setCurrentBlogId] = useState(parseInt(blogId, 10));
  const [currentBlog, setCurrentBlog] = useState(null);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://pub-assets.aurm.in/json%2Fblog_v1.json');
        const data = await response.json();
        const blogsData = data.blogs;
        setBlogs(blogsData);

        const foundBlog = blogsData.find(blog => blog.id === currentBlogId);
        setCurrentBlog(foundBlog);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [currentBlogId]);

  return (
    <div id="individual_blogs_page_div">
      <div id="individual_blogs_header">
        <HeaderComponent />
      </div>
      <div>
      {currentBlog && (
        <>
          <picture>
            <source srcSet={currentBlog.webp_poster} type="image/webp" />
            <img className='banner_height individual_blogs_banner' src={currentBlog.poster} alt="individual_blog_banner" loading='lazy'/>
          </picture>
        </>
       
      )}
      </div>
      <div className="container individual_blogs_container">
        <div className="individual_blog_content">
          <div className="row">
            <div className="col-md-1 col-sm-2 col-xs-12"></div>
            <div className="col-md-10 col-sm-8 col-xs-12">
              {currentBlog && (
                <>
                  <div id="individual_blog_published_text">
                    <p>Published {currentBlog.publishedAt}</p>
                  </div>
                  <div id="individual_blog_locker_text">
                    <p>{currentBlog.title}</p>
                  </div>
                  <div id="individual_blog_tag" className='d-none'>
                    <div className="individual-blog-custom-box individual-blog-design-box"><p className="individual-blog-design-text">Design</p></div>
                    <div className="individual-blog-custom-box individual-blog-research-box"><p className="individual-blog-research-text">Research</p></div>
                    <div className="individual-blog-custom-box individual-blog-presentation-box"><p className="individual-blog-presentation-text">Presentation</p></div>
                  </div>
                  <div>
                    <div className='d-flex justify-content-center align-items-center individual-blog-tag-div'>
                      <div className='me-3'>
                        <picture>
                          <source srcSet={currentBlog.publisherWebpImage} type="image/webp" />
                          <img src={currentBlog.publisherImage} alt="BlogPost_Publisher" style={{ width: '40px', height: '40px', borderRadius: '9999px', border: '0.75px' }} loading='lazy' />
                        </picture>
                      </div>
                      <div className='blog_post_publisher_name blog_post_info_published_color'>
                      {currentBlog.publishedBy}<br /><span className='blog_post_published_on'>{currentBlog.publishedAt}</span>
                      </div>
                    </div>
                  </div>
                  <div id="individual_blog_main_text">
                    <div dangerouslySetInnerHTML={{ __html: currentBlog.description }} />
                  </div>
                </>
              )}
            </div>
            <div className="col-md-1 col-sm-2 col-xs-12"></div>
          </div>
        </div>
      </div>
      <div className="indvidual_blog_blocg_list">
        <BlogsListComponent
          is_individual_page="true"  
        />
      </div>
      <div id="blogs_footer">
        <FooterComponent />
      </div>
    </div>
  );
}

export default IndividualBlogsComponent;
