import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/comparison_matrix.css";
import brand_logo from "../assets/images/Aurm_Logo.svg";
import star_img from "../assets/images/Star.svg";
import cross_icon from "../assets/images/cross.svg";
import check_mark from "../assets/images/check_mark.svg";
import dash_line from "../assets/images/dash_line.svg";

/**
    Shows the Comparison Matrix section
*/

function ComparisonMatrixComponent() {
  return (
    <section id="comparisonmatrix">
      <div className="container mt-3" id="comparision_matrix_div">
        <div className="text-center" id="comparision_head">
          How is <span className="safe_text">Aurm</span> better?
        </div>
        <div className="table-responsive table-responsive-lg">
          <table className="table table-responsive custom-table">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="matrix_table_head br-lft-n bdr-tp-n"
                ></th>
                <th
                  scope="col"
                  className="matrix_head logo_table_div_color bdr-tp-n"
                  id=""
                >
                  <img
                    className="aurm_table_logo"
                    src={brand_logo}
                    alt="aurm"
                    loading="lazy"
                  />
                </th>
                <th scope="col" className="matrix_head bdr-tp-n">
                  Bank Locker
                </th>
                <th scope="col" className="matrix_head br-rght-n bdr-tp-n">
                  Home Safe
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="">
                <td className="matrix_th bdr-btm-n br-lft-n">
                  <span className="matrix_sub_head">
                    <img
                      className="matrix_star_img"
                      src={star_img}
                      alt="star"
                      loading="lazy"
                    />
                    <span>Convenience </span>
                  </span>
                </td>
                <td className="bdr-btm-n logo_table_div_color">
                  <div className="th_matrix_option">&nbsp;</div>
                </td>
                <td className="bdr-btm-n">
                  <div className="th_matrix_option">&nbsp;</div>
                </td>
                <td className="bdr-btm-n br-rght-n">
                  <div className="th_matrix_option">&nbsp;</div>
                </td>
              </tr>
              <tr className="">
                <td className="matrix_sub_head_options bdr-y-n br-lft-n">
                  Proximity to home
                </td>
                <td className="th_matrix_option bdr-y-n logo_table_div_color">
                  <img src={check_mark} alt="tick" loading="lazy" />
                </td>
                <td className="th_matrix_option bdr-y-n">
                  <img src={cross_icon} alt="cross" loading="lazy" />
                </td>
                <td className="th_matrix_option bdr-y-n br-rght-n">
                  <img src={check_mark} alt="tick" loading="lazy" />
                </td>
              </tr>
              <tr>
                <td className="matrix_sub_head_options bdr-y-n br-lft-n">
                  Anytime access
                </td>
                <td className="th_matrix_option bdr-y-n logo_table_div_color">
                  <img src={check_mark} alt="tick" loading="lazy" />
                </td>
                <td className="th_matrix_option bdr-y-n">
                  <img src={cross_icon} alt="cross" loading="lazy" />
                </td>
                <td className="th_matrix_option bdr-y-n br-rght-n">
                  <img src={check_mark} alt="tick" loading="lazy" />
                </td>
              </tr>
              <tr className="">
                <td className="matrix_sub_head_options bdr-y-n br-lft-n">
                  Choice of payment plans
                </td>
                <td className="th_matrix_option bdr-y-n logo_table_div_color">
                  <img src={check_mark} alt="tick" loading="lazy" />
                </td>
                <td className="th_matrix_option bdr-y-n">
                  <img src={cross_icon} alt="cross" loading="lazy" />
                </td>
                <td className="th_matrix_option bdr-y-n br-rght-n">
                  <img src={cross_icon} alt="cross" loading="lazy" />
                </td>
              </tr>
              <tr className="">
                <td className="matrix_sub_head_options bdr-tp-n br-lft-n tr_last_column">
                  No other requirements (FDs etc.)
                </td>
                <td className="th_matrix_option bdr-tp-n logo_table_div_color tr_last_column">
                  <img src={check_mark} alt="tick" loading="lazy" />
                </td>
                <td className="th_matrix_option bdr-tp-n tr_last_column">
                  <img src={cross_icon} alt="cross" loading="lazy" />
                </td>
                <td className="th_matrix_option bdr-tp-n br-rght-n tr_last_column">
                  <img src={check_mark} alt="tick" loading="lazy" />
                </td>
              </tr>
              <tr className="">
                <td className="matrix_th bdr-btm-n br-lft-n">
                  <span className="matrix_sub_head">
                    <img
                      className="matrix_star_img"
                      src={star_img}
                      alt="star"
                      loading="lazy"
                    />
                    <span>Security </span>
                  </span>
                </td>
                <td className="bdr-btm-n logo_table_div_color">
                  <div className="th_matrix_option">&nbsp;</div>
                </td>
                <td className="bdr-btm-n">
                  <div className="th_matrix_option">&nbsp;</div>
                </td>
                <td className="bdr-btm-n br-rght-n">
                  <div className="th_matrix_option">&nbsp;</div>
                </td>
              </tr>
              <tr className="">
                <td className="matrix_sub_head_options bdr-y-n br-lft-n">
                  Military grade strong room
                </td>
                <td className="th_matrix_option bdr-y-n logo_table_div_color">
                  <img src={check_mark} alt="tick" loading="lazy" />
                </td>
                <td className="th_matrix_option bdr-y-n">
                  <img src={check_mark} alt="tick" loading="lazy" />
                </td>
                <td className="th_matrix_option bdr-y-n br-rght-n">
                  <img src={cross_icon} alt="cross" loading="lazy" />
                </td>
              </tr>
              <tr>
                <td className="matrix_sub_head_options bdr-y-n br-lft-n">
                  Active surveillance
                </td>
                <td className="th_matrix_option bdr-y-n logo_table_div_color">
                  <img src={check_mark} alt="tick" loading="lazy" />
                </td>
                <td className="th_matrix_option bdr-y-n">
                  <img src={cross_icon} alt="cross" loading="lazy" />
                </td>
                <td className="th_matrix_option bdr-y-n br-rght-n">
                <img src={cross_icon} alt="cross" loading="lazy" />
                </td>
              </tr>
              <tr className="">
                <td className="matrix_sub_head_options bdr-y-n br-lft-n">
                  Intrusion detection systems
                </td>
                <td className="th_matrix_option bdr-y-n logo_table_div_color">
                  <img src={check_mark} alt="tick" loading="lazy" />
                </td>
                <td className="th_matrix_option bdr-y-n">
                <img src={cross_icon} alt="cross" loading="lazy" />
                </td>
                <td className="th_matrix_option bdr-y-n br-rght-n">
                <img src={cross_icon} alt="cross" loading="lazy" />
                </td>
              </tr>
              <tr className="">
                <td className="matrix_sub_head_options bdr-tp-n br-lft-n tr_last_column">
                  Insurance for valuables
                </td>
                <td className="th_matrix_option bdr-tp-n logo_table_div_color tr_last_column">
                  <img src={check_mark} alt="tick" loading="lazy" />
                </td>
                <td className="th_matrix_option bdr-tp-n tr_last_column">
                <img src={dash_line} alt="dash" loading="lazy" />
                </td>
                <td className="th_matrix_option bdr-tp-n br-rght-n tr_last_column">
                  <img src={cross_icon} alt="cross" loading="lazy" />
                </td>
              </tr>
              <tr className="">
                <td className="matrix_th bdr-btm-n br-lft-n">
                  <span className="matrix_sub_head">
                    <img
                      className="matrix_star_img"
                      src={star_img}
                      alt="star"
                      loading="lazy"
                    />
                    <span>Experience </span>
                  </span>
                </td>
                <td className="bdr-btm-n logo_table_div_color">
                  <div className="th_matrix_option">&nbsp;</div>
                </td>
                <td className="bdr-btm-n">
                  <div className="th_matrix_option">&nbsp;</div>
                </td>
                <td className="bdr-btm-n br-rght-n">
                  <div className="th_matrix_option">&nbsp;</div>
                </td>
              </tr>
              <tr className="">
                <td className="matrix_sub_head_options bdr-y-n br-lft-n">
                  Dressing room ambience
                </td>
                <td className="th_matrix_option bdr-y-n logo_table_div_color">
                  <img src={check_mark} alt="tick" loading="lazy" />
                </td>
                <td className="th_matrix_option bdr-y-n">
                  <img src={cross_icon} alt="cross" loading="lazy" />
                </td>
                <td className="th_matrix_option bdr-y-n br-rght-n">
                  <img src={check_mark} alt="tick" loading="lazy" />
                </td>
              </tr>
              <tr>
                <td className="matrix_sub_head_options bdr-y-n br-lft-n">
                  Inventory tracking
                </td>
                <td className="th_matrix_option bdr-y-n logo_table_div_color">
                  <img src={check_mark} alt="tick" loading="lazy" />
                </td>
                <td className="th_matrix_option bdr-y-n">
                <img src={cross_icon} alt="cross" loading="lazy" />
                </td>
                <td className="th_matrix_option bdr-y-n br-rght-n">
                <img src={cross_icon} alt="cross" loading="lazy" />
                </td>
              </tr>
              <tr className="">
                <td className="matrix_sub_head_options bdr-y-n br-lft-n">
                  Full view of contents
                </td>
                <td className="th_matrix_option bdr-y-n logo_table_div_color">
                  <img src={check_mark} alt="tick" loading="lazy" />
                </td>
                <td className="th_matrix_option bdr-y-n">
                <img src={cross_icon} alt="cross" loading="lazy" />
                </td>
                <td className="th_matrix_option bdr-y-n br-rght-n">
                <img src={cross_icon} alt="cross" loading="lazy" />
                </td>
              </tr>
              <tr className="">
                <td className="matrix_sub_head_options bdr-y-n br-lft-n tr_last_column">
                  Weight tracking
                </td>
                <td className="th_matrix_option bdr-y-n logo_table_div_color tr_last_column">
                  <img src={check_mark} alt="tick" loading="lazy" />
                </td>
                <td className="th_matrix_option bdr-y-n tr_last_column">
                <img src={cross_icon} alt="cross" loading="lazy" />
                </td>
                <td className="th_matrix_option bdr-y-n br-rght-n tr_last_column">
                  <img src={dash_line} alt="dash" loading="lazy" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default ComparisonMatrixComponent;
