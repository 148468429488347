import React from "react";
import "../assets/css/comitment_standard.css";

/**
  Commitment standard component
*/
function CommitmentStardardComponent() {
  return (
    <section id="comitmet_standard_section">
      <div id="comitmet_main_div">
        <div id="comitmet_header" className="text-center">
          Aurm is committed to the <br />
          <span id="comitment_header_sub_text">highest standards</span>
        </div>
        <div className="container standard_images_container">
          <div className="row justify-content-evenly standard_iso_row">
            <div className="col-md-4 standard_img_div text-center">
            <a href="https://m.economictimes.com/industry/banking/finance/insure/tata-aig-general-insurance-partners-aurm-for-its-vault-services/amp_articleshow/110118817.cms">
              <picture>
                <source srcSet="https://pub-assets.aurm.in/committed_images%2Ftata_ic.webp" type="image/webp" />
                <img className='standard_image' src="https://pub-assets.aurm.in/committed_images%2Ftata_ic.png" alt='action' loading="lazy" />
              </picture>
              <div className="text-center pt-2">
                <span className="standard_text text-white text-center">
                  Insurance Partner TATA AIG
                </span>
              </div>
              </a>
            </div>
            <div className="col-md-4 standard_img_div text-center">
              <picture>
                <source srcSet="https://pub-assets.aurm.in/committed_images%2F9001_v1.webp" type="image/webp" />
                <img className='standard_image' src="https://pub-assets.aurm.in/committed_images%2F9001_v1.png" alt='action' loading="lazy" />
              </picture>
              <div className="text-center pt-2">
                <span className="standard_text text-white text-center">
                  ISO 9001:2015 certified
                </span>
              </div>
            </div>
            <div className="col-md-4 standard_img_div text-center">
              <picture>
                <source srcSet="https://pub-assets.aurm.in/committed_images%2F27001_v1.webp" type="image/webp" />
                <img className='standard_image' src="https://pub-assets.aurm.in/committed_images%2F27001_v1.png" alt='action' loading="lazy" />
              </picture>
              <div className="text-center pt-2">
                <span className="standard_text text-white text-center">
                  ISO 27001:2022 certified
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CommitmentStardardComponent;
