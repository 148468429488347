import React, { useEffect, useState } from "react";
import "../assets/css/contact_us.css";
import $ from "jquery";
import success_logo from "../assets/images/check_verified.svg";
const countryCodes = require("country-codes-list");
const CountryCodesObject = countryCodes.customList(
  "countryCode",
  "{countryCallingCode}"
);

/**
 * Shows the Contact Us Section
 */

function ContactUSComponent() {
  const [selectedCountry, setSelectedCountry] = useState("91");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phonePlaceholder, setPhonePlaceholder] = useState("----------");
  const [showSubmitForm, setShowSubmitForm] = useState(false);
  const [inputFields, setInputFields] = useState({
    "i-fname": "",
    "i-lname": "",
    "i-email": "",
    "i-phone_number": "",
  });

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
    const selectedCountryCode = e.target.value;
    const selectedCountry = countryCodes.find(
      (country) => country.code === selectedCountryCode
    );
    const placeholder = selectedCountry
      ? `+${selectedCountry.code} ----------`
      : "";
    setSelectedCountry(selectedCountryCode);
    setPhoneNumber("");
    setPhonePlaceholder(placeholder);
  };

  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const countryCodes = [];
  for (const countryCode in CountryCodesObject) {
    if (CountryCodesObject.hasOwnProperty(countryCode)) {
      const phoneCode = CountryCodesObject[countryCode];
      countryCodes.push({ code: phoneCode, country: countryCode });
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputFields({
      ...inputFields,
      [name]: value,
    });
  };

  const filledFieldsCount = Object.values(inputFields).filter(
    (value) => value.trim() !== ""
  ).length;

  useEffect(() => {
    if (filledFieldsCount && filledFieldsCount === 3) {
      $("#contact_submit_btn").addClass("succes_radiant_color");
      $("#contact_submit_btn").attr("disabled", false);
    } else {
      $("#contact_submit_btn").removeClass("succes_radiant_color");
      $("#contact_submit_btn").attr("disabled", true);
    }
    if (filledFieldsCount > 0) {
      $("#progess_bar_div").removeClass("opacity");
      $("#progess_bar_div").addClass("light_blue");
    } else {
      $("#progess_bar_div").addClass("opacity");
      $("#progess_bar_div").removeClass("light_blue");
    }
  }, [filledFieldsCount]);

  useEffect(() => {
    const currentPath = window.location.search;
    if (currentPath && currentPath.includes("isSuccess=true")) {
      setShowSubmitForm(false);
      const contactSection = document.getElementById("contact_section");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      setShowSubmitForm(true);
    }
  }, []);

  return (
    <div
      className="container-fluid contact_section col-md-12"
      id="contact_section"
    >
      <div className="row justify-content-center" id="contact_row_div">
        <div className="col-lg-6 col-md-12" id="contact_form_div">
          <div id="contact_form_section">
            <div id="contact_form_header">
              <span>
                <span className="contact_form_header_text"> Or </span>
                <span className="gradient_font_color" id="write_text">
                  write{" "}
                </span>{" "}
                <span className="contact_form_header_text"> to us</span>{" "}
              </span>
            </div>

            <div id="contact_form_sub_text">
              You can reach us anytime via
              <a href="mailto:hello@aurm.in" className="gradient_font_color">
                {" "}
                hello@aurm.in
              </a>
              <br></br>
              <br></br>
              {/* <a href="tel:+919740741016" className="gradient_font_color"> +919740741016</a>  */}
            </div>

            {showSubmitForm ? (
              <div className="contact_form" id="form_body">
                <form
                  method="POST"
                  action="/api/submit"
                  encType="multipart/form-data"
                >
                  <div className="form-group">
                    <label htmlFor="first_name" className="form_label">
                      Name <span className="required">*</span>
                    </label>

                    <input
                      type="text"
                      name="i-fname"
                      className="form-control form_input"
                      id="i-fname"
                      placeholder="Name"
                      pattern="([^\s][A-z0-9À-ž\s]+)"
                      required
                      value={inputFields["i-fname"]}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email" className="form_label">
                      Email address <span className="required">*</span>
                    </label>
                    <input
                      type="email"
                      name="i-email"
                      className="form-control form_input"
                      id="i-email"
                      placeholder="you@company.com"
                      pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                      required
                      title="Enter a valid email address"
                      value={inputFields["i-email"]}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="phone" className="form_label">
                      Phone Number <span className="required">*</span>
                    </label>
                    <div className="phone_input">
                      <div className="country_select">
                        <select
                          value={selectedCountry}
                          id="i-phone_num_country_code"
                          onChange={handleCountryChange}
                        >
                          {countryCodes.map((country) => (
                            <option
                              key={`${country.code}-${country.country}`}
                              value={country.code}
                            >
                              {country.country}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="phone_number_input ">
                        <input
                          type="text"
                          placeholder={phonePlaceholder}
                          id="i-phone_number"
                          name="i-phone_number"
                          value={phoneNumber}
                          onChange={(e) => {
                            handlePhoneChange(e);
                            handleInputChange(e);
                          }}
                          pattern="[0-9]{10}"
                          title="Enter a valid phone number with 10 digits"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="" id="regarding_section">
                    <div className="form-group">
                      <label htmlFor="message" className="form_label">
                        Message
                      </label>

                      <textarea
                        name="i-message"
                        className="form-control form_input_textarea"
                        id="i-message"
                        placeholder="Leave us a message..."
                      ></textarea>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary"
                    id="contact_submit_btn"
                    disabled
                  >
                    Submit
                  </button>
                </form>
              </div>
            ) : (
              <div id="contact_submit_success">
                <div className="text-center">
                  <img src={success_logo} height={100} width={100} alt="logo" />
                </div>
                <div className="text-left" id="contact_submit_success_text">
                  Thank you for writing in. Someone from our team will reach out
                  within 24 hours.
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="col-lg-6 col-md-12 p-0" id="map_section">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.904700657746!2d77.6419216!3d12.9138463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae148ff6e27ea5%3A0x8a07204fd2a90e3b!2s534%2C%20534%2C%2015th%20Cross%20Rd%2C%20Sector%204%2C%20HSR%20Layout%2C%20Bengaluru%2C%20Karnataka%20560034!5e0!3m2!1sen!2sin!4v1706071441276!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Aurm Location on Google Maps"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default ContactUSComponent;
