import React, { useEffect }  from "react";
import "../assets/css/our_story_styles.css";
import "../assets/css/global_styles.css";
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import WatchVideoComponent from "./WatchVideoComponent";
import frameImage from "../assets/images/frame.svg";

/**
  Loads Our Story Page
*/
function OurStoryPageComponent() {
  useEffect(() => {
    const scroll_to = document.getElementById("our_story_page_div");
    if (scroll_to) {
      scroll_to.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <div id="our_story_page_div">
    <div className="container" id="our_story_section_container">
      <div id="story_header">
        <HeaderComponent header_request_from="our_story_page" />
      </div>
      <div className="" id="our_story_container">
        <div className="col-md-12">
          <div
            className="col-md-8 m-auto text-center"
            id="story_header_container"
          >
            <div id="strory_main_head">Our Story</div>
            <div id="story_sub_head">
              We Indians have an emotional connection with our wealth
            </div>
            <div id="story_sub_head_2">
              Did you know that 12% of the world’s gold is held in Indian
              households? That’s 25,000 tonnes of the precious metal, equal to
              50% of the country’s GDP in 2022!
            </div>
          </div>
        </div>
      </div>
      <section id="first_vid_scn" className="pt-0">
        <WatchVideoComponent ourstory_first_vd={true} />
      </section>
      <section id="endover_text">
        <div className=" pt-5 mt-5" id="endover_text_div">
          <div id="aurm_banner_content" className="text-center">
            Aurm is our endeavour to craft the experience of safeguarding and
            accessing your wealth in the most secure, convenient, and joyous way
            possible.
          </div>
          <div id="aurm_banner_sub_content" className="text-center pt-3">
            We offer safe deposit storage in your residential community, with
            state-of-the-art security and an access experience like your own
            dressing room, that’s available 24/7, 365 days a year.
          </div>
        </div>
      </section>

      <section id="aurm_banner_section">
        <div
          className=" position-relative pt-5 mt-5"
          id="aurm_banner_section_desktop"
        >
          <div className="overlay-div position-absolute bottom-0 start-50 translate-middle-x ">
            <div id="aurm_banner_header_text" className="text-center pb-3">
              We would love to store our valuables
            </div>
            <div className="main_svg_div">
              <div className="frame_div d-flex">
                <div className="feame_svg_div">
                  <img src={frameImage} alt="frame" width="28" height="36"></img>
                </div>
                <div className="frame_svg_text">in a safe space</div>
              </div>
              <div className="frame_div d-flex">
                <div className="feame_svg_div">
                  <img src={frameImage} alt="frame" width="28" height="36"></img>
                </div>
                <div className="frame_svg_text">that is closer to us</div>
              </div>
              <div className="frame_div d-flex">
                <div className="feame_svg_div">
                  <img src={frameImage} alt="frame" width="28" height="36"></img>
                </div>
                <div className="frame_svg_text">
                  allows access whenever we wish to
                </div>
              </div>
              <div className="frame_div d-flex">
                <div className="feame_svg_div">
                  <img src={frameImage} alt="frame" width="28" height="36"></img>
                </div>
                <div className="frame_svg_text">
                  provides a joyous experience
                </div>
              </div>
            </div>
          </div>
          <picture>
            <source
              srcSet="https://pub-assets.aurm.in/story_page_images%2Faurm_banner.webp"
              type="image/webp"
            />
            <img
              src="https://pub-assets.aurm.in/story_page_images/aurm_banner.png"
              className="aurm_img_banner img-fluid"
              alt="story_img_1"
              loading="lazy"
            />
          </picture>
        </div>
      </section>
      <section>
        <div
          className=" position-relative d-none pt-5 mt-5"
          id="aurm_banner_section_mobile"
        >
          <div className="overlay-div position-absolute bottom-0 start-50 translate-middle-x ">
            <div
              id="aurm_banner_mobile_header_text"
              className="text-center pb-3"
            >
              We would love to store our valuables
            </div>
            <div className="main_svg_div_mobile d-flex justify-content-start">
              <div className="frame_div d-flex">
                <div className="feame_svg_div">
                  <img src={frameImage} alt="frame" width="13" height="17"></img>
                </div>
                <div className="frame_svg_text access_frame_div">
                  in a safe space
                </div>
              </div>
              <div className="frame_div d-flex">
                <div className="feame_svg_div">
                  <img src={frameImage} alt="frame" width="13" height="17"></img>
                </div>
                <div className="frame_svg_text mobile_svg_text">
                  that is closer to us
                </div>
              </div>
            </div>
            <div className="main_svg_div_mobile d-flex justify-content-start">
              <div className="frame_div d-flex">
                <div className="feame_svg_div">
                  <img src={frameImage} alt="frame" width="13" height="17"></img>
                </div>
                <div className="frame_svg_text mobile_svg_text">
                  allows access whenever we wish to
                </div>
              </div>
              <div className="frame_div d-flex">
                <div className="feame_svg_div">
                  <img src={frameImage} alt="frame" width="13" height="17"></img>
                </div>
                <div className="frame_svg_text mobile_svg_text">
                  provides a joyous experience
                </div>
              </div>
            </div>
          </div>
          <img
            width={"100%"}
            src="https://pub-assets.aurm.in/story_page_images/aurm_banner_img_mob.png"
            className="aurm_img_banner img-fluid"
            alt="story_img_1"
          />
        </div>
      </section>

      <section id="story_gallery_section_2" className="pt-5 mt-5">
        <div className=" p-0" id="image_gallery_div2">
          <div className="bg-transparent d-flex flex-column justify-content-between">
            <div className="row margin-top-class story_gallery_section_row">
              <div className="col-6 padding_x_none">
                <div className="gallery_btn_div text-center gallery_btn_font_style">
                  Privacy
                </div>
              </div>
              <div className="col-6 padding_end_none">
                <div className="gallery_btn_div text-center gallery_btn_font_style">
                  Peace of mind
                </div>
              </div>
            </div>
            <div className="row story_gallery_section_row">
              <div className="col-4 d-flex flex-column justify-content-between padding_start_none">
                <img
                  src="https://pub-assets.aurm.in/story_page_images/Rectangle_46.png"
                  className="img-fluid margin-top-class laser_stairs_img"
                  alt="story_img_1"
                  style={{ maxWidth: "100%", width: "100%" }}
                />
                <div className="gallery_btn_div margin-top-class text-center gallery_btn_font_style">
                  Delight
                </div>
              </div>
              <div className="col-4 d-flex flex-column justify-content-between padding_x_none">
                <picture>
                  <source
                    srcSet="https://pub-assets.aurm.in/story_page_images%2FRectangle_47.webp"
                    type="image/webp"
                  />
                  <img
                    src="https://pub-assets.aurm.in/story_page_images/Rectangle_47.png"
                    className="img-fluid margin-top-class"
                    alt="story_img_1"
                    style={{ maxWidth: "100%", width: "100%" }}
                    loading="lazy"
                  />
                </picture>
                <div className="gallery_btn_div margin-top-class text-center gallery_btn_font_style">
                  Convenience
                </div>
                <picture>
                  <source
                    srcSet="https://pub-assets.aurm.in/story_page_images%2FRectangle_48.webp"
                    type="image/webp"
                  />
                  <img
                    src="https://pub-assets.aurm.in/story_page_images/Rectangle_48.png"
                    className="img-fluid margin-top-class"
                    alt="story_img_1"
                    style={{ maxWidth: "100%", width: "100%" }}
                    loading="lazy"
                  />
                </picture>
              </div>
              <div className="col-4 padding_end_none d-flex flex-column justify-content-between">
                <img
                  src="https://pub-assets.aurm.in/story_page_images/Rectangle_49.png"
                  className="img-fluid margin-top-class laser_stairs_img"
                  alt="story_img_1"
                  style={{ maxWidth: "100%", width: "100%" }}
                />
                <div className="gallery_btn_div margin-top-class text-center gallery_btn_font_style">
                  Safety
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="affilate_section3">
        <div className=" mt-5 pt-5" id="affilate_section3">
          <div className="afilated_row row">
            <div className="col-md-5 p-0 mt-2" id="bulid_img_one">
              <picture>
                <source
                  srcSet="https://pub-assets.aurm.in/story_page_images%2Fprotection_img.webp"
                  type="image/webp"
                />
                <img
                  className="img-fluid"
                  src="https://pub-assets.aurm.in/story_page_images/protection_img.png"
                  alt="Responsive"
                  id="building_img"
                  loading="lazy"
                ></img>
              </picture>
            </div>
            <div className="col-md-6 p-0 afilated_text_div">
              <div className="build_header text-white">Protection</div>
              <div className="build_header_2 text-white">
                Peace of mind comes standard with Aurm vaults
              </div>
              <div className="build_sub_head">
                <div className="d-flex" id="sub_head_text">
                  <span className="pe-3 d-flex align-items-top dot_list">
                    .
                  </span>
                  <span className="flex-grow-1">
                    We have combined the strongest materials with the smartest
                    technology.
                  </span>
                </div>
                <div className="d-flex">
                  <span className="pe-3 d-flex align-items-top dot_list">
                    .
                  </span>
                  <span className="flex-grow-1">
                    Every safe becomes safer with adequate insurance cover for
                    your valuables.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="affilate_section4">
        <div className=" mt-5 pt-5" id="affilate_section3">
          <div className="afilated_row row">
            <div className="col-md-6 p-0 afilated_text_div">
              <div className="build_header text-white">Convenience</div>
              <div className="build_header_2 text-white">
                Your prized possessions are yours to use and flaunt, not just
                lock away.
              </div>
              <div className="build_sub_head">
                <div className="d-flex" id="sub_head_text">
                  <span className="pe-3 d-flex align-items-top dot_list">
                    .
                  </span>
                  <span className="flex-grow-1">
                    Aurm is at the clubhouse in your community, and available
                    for residents.
                  </span>
                </div>
                <div className="d-flex">
                  <span className="pe-3 d-flex align-items-top dot_list">
                    .
                  </span>
                  <span className="flex-grow-1">
                    Our vaults are open 24/7/365, so you can access your
                    treasures whenever you need to.
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-5 p-0 mt-2" id="bulid_img_one">
              <picture>
                <source
                  srcSet="https://pub-assets.aurm.in/story_page_images%2Fconvenience.webp"
                  type="image/webp"
                />
                <img
                  className="img-fluid float-right"
                  src="https://pub-assets.aurm.in/story_page_images/convenience.png"
                  alt="clubhouse"
                  id="building_img"
                  loading="lazy"
                ></img>
              </picture>
            </div>
          </div>
        </div>
      </section>
      <section id="affilate_section5">
        <div className=" mt-5 pt-5" id="affilate_section3">
          <div className="afilated_row row">
            <div className="col-md-5 p-0 mt-2" id="bulid_img_one">
              <picture>
                <source
                  srcSet="https://pub-assets.aurm.in/story_page_images%2Fexperience.webp"
                  type="image/webp"
                />
                <img
                  className="img-fluid"
                  src="https://pub-assets.aurm.in/story_page_images/experience.png"
                  alt="Responsive"
                  id="building_img"
                  loading="lazy"
                ></img>
              </picture>
            </div>
            <div className="col-md-6 p-0 afilated_text_div">
              <div className="build_header text-white">Experience</div>
              <div className="build_header_2 text-white">
              You should be as comfortable as in your own home while accessing your treasures{" "}
              </div>
              <div className="build_sub_head">
                <div className="d-flex" id="sub_head_text">
                  <span className="pe-3 d-flex align-items-top dot_list">
                    .
                  </span>
                  <span className="flex-grow-1">
                    Aurm offers a luxurious dressing room ambience where your
                    safe is presented to you.
                  </span>
                </div>
                <div className="d-flex">
                  <span className="pe-3 d-flex align-items-top dot_list">
                    .
                  </span>
                  <span className="flex-grow-1">
                    Enjoy music that is crafted exclusively for Aurm, evoking
                    anticipation, joy and contentment.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="affilate_section6">
        <div className=" mt-5 pt-5" id="affilate_section3">
          <div className="afilated_row row">
            <div className="col-md-6 p-0 afilated_text_div">
              <div className="build_header text-white">
                Beauty is in the details
              </div>
              <div className="build_header_2 text-white">
              While building Aurm, we paid attention to the small details that delight you.
              </div>
              <div className="build_sub_head">
                <div className="d-flex" id="sub_head_text">
                  <span className="pe-3 d-flex align-items-top dot_list">
                    .
                  </span>
                  <span className="flex-grow-1">
                  You can secure the safe with your own lock, and keep track of what's inside too. You're in control of your safe, always!
                  </span>
                </div>
                <div className="d-flex">
                  <span className="pe-3 d-flex align-items-top dot_list">
                    .
                  </span>
                  <span className="flex-grow-1">
                  Every safe is weighed before and after you access it. Stay assured that your treasures are safe in the Aurm vault.
                  </span>
                </div>
      
              </div>
            </div>
            <div className="col-md-5 p-0 mt-2" id="bulid_img_one">
              <picture>
                <source
                  srcSet="https://pub-assets.aurm.in/story_page_images%2Fbeauty_inside.webp"
                  type="image/webp"
                />
                <img
                  className="img-fluid float-right"
                  src="https://pub-assets.aurm.in/story_page_images/beauty_inside.png"
                  alt="clubhouse"
                  id="building_img"
                  loading="lazy"
                ></img>
              </picture>
            </div>
          </div>
        </div>
      </section>
      </div>
      <section id="how_it_works_video_section" className="pt-5">
        <WatchVideoComponent ourstory_second_vd={true} />
      </section>
      
      <div id="story_footer">
        <FooterComponent />
      </div>
    </div>
  );
}

export default OurStoryPageComponent;
