import React from "react";
import "../assets/css/team_card.css";
import linkedInImage from "../assets/images/linked_in.svg";

/**
  Card for each Team member.
*/
function TeamCardComponent(props) {
  const {
    PersonImage,
    PersonImageWebp,
    PersonName,
    PersonDesignation,
    LinkedinLink,
    PersonDescription,
  } = props;

  const isClickable = Boolean(LinkedinLink);
  return (
    isClickable?(
      <a href={LinkedinLink} target="_blank" rel="noreferrer">
      <div className="card" id="teamcard_container">
      <div className="team-profile-images">
        <picture>
          <source srcSet={PersonImageWebp} type="image/webp" />
          <img className='card-img-top team_card_img' src={PersonImage} alt='person_image' style={{ width: "100%" }} loading="lazy" />
        </picture>
        </div>
        <div className="team_card_content">
        <div className="d-flex justify-content-center linked_in_cards"  id="team_card_head"> 
          <div id="team_card_title" className="pe-2 text-center" title={PersonName}>
            {PersonName}
          </div>
          <span id="linked_in_div">
            <img src={linkedInImage} alt="linkedin" className="teamcard_linked_in_image" width="32" height="33" loading="lazy"></img>
          </span>
        </div>
        <div className="pb-3 team_card_desg_color" title={PersonDesignation} id="team_card_designation">
          {PersonDesignation}
        </div>
        <div id="team_card_description" className="text-break">{PersonDescription}</div></div>
      </div>
      </a>
    ) : (
      <div className="card" id="teamcard_container">
      <div className="team-profile-images">
        <picture>
          <source srcSet={PersonImageWebp} type="image/webp" />
          <img className='card-img-top team_card_img' src={PersonImage} alt='person_image' style={{ width: "100%" }} loading="lazy"/>
        </picture>
        </div>
        <div className="team_card_content">
        <div className="text-center" id="team_card_head">          
          <div id="team_card_title" className="" title={PersonName}>
            {PersonName}
          </div>
        </div>
        <div className="pb-3 team_card_desg_color" title={PersonDesignation} id="team_card_designation">
          {PersonDesignation}
        </div>
        <div id="team_card_description" className="text-break">{PersonDescription}</div></div>
      </div>
    )
  );
}

export default TeamCardComponent;
