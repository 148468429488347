import "../assets/css/book_your_aurm_safe.css";
import aurm_playstore_img from "../assets/images/book_your_aurm_safe_playstore_img.svg"
import aurm_appstore_img from "../assets/images/book_your_aurm_safe_appstore_img.svg"
import brand_logo from "../assets/images/Aurm_Logo.svg";
/*
  Pre book your aurm safe section component.
*/
function BookYOurAurmSafe() {
  const playStoreUrl = "https://play.google.com/store/apps/details?id=com.aurm.userapp";
  const appStoreUrl = " https://apps.apple.com/us/app/aurm/id6451835407";
  return (
    <div id="book_your_aurm_safe_root">
        <section id="header_section">
          <header id="">
            <nav>
              <a className="" href="/">
                <img className="aurm_logo" src={brand_logo} alt="aurm" loading="lazy" />
              </a> 
            </nav>
          </header>
        </section>
        <div id="book_your_aurm_safe_section">
        
          <div className="book_ypur_aurm_safe_content_container text-center">
            
            <div className="book_your_aurm_safe_secondary_text text-center">
            Download the Aurm app and register to get your safe
            </div>
            <div className="d-flex justify-content-center mt-3 book_your_aurm_safe_img_div">
            <img
              className="book_your_aurm_safe_playstore_imgs"
              src={aurm_playstore_img}
              alt=""
              onClick={() => window.open(playStoreUrl, '_blank')}
            />
            <img
              className="book_your_aurm_safe_playstore_imgs"
              src={aurm_appstore_img}
              alt=""
              onClick={() => window.open(appStoreUrl, '_blank')}
              loading="lazy"
            />
            </div>
          </div>
          </div>
    </div>
  );
}

export default BookYOurAurmSafe;
