import "../assets/css/reach_us.css";
import whatsapp from "../assets/images/whatsapp.svg";

/*
  Reach us section component.
*/
function ReachUsComponent() {
  return (
    <div id="reach_us_section">
      <div>
        <div className="d-flex justify-content-center">
          <div className="reachus_content_container">
            <div className="reachus_primary_text">
             <a href="https://api.whatsapp.com/send/?phone=919740741016&text=I'm intrigued about Aurm. Tell me more! &type=phone_number&app_absent=0" target="_blank" rel="noreferrer">
              <span className="white_text mt-4">Simply reach us on</span>
                <span className="whatsapp_text_color"> WhatsApp</span>
             </a>
            </div>
            <div className="reachus_secondary_text text-center">
              Hear back from us within 12 hours
            </div>
          </div>
          <a href="https://api.whatsapp.com/send/?phone=919740741016&text=I'm intrigued about Aurm. Tell me more! &type=phone_number&app_absent=0" target="_blank" rel="noreferrer">
            <img
              className="reach_us_whatsapp_img position-absolute"
              src={whatsapp}
              alt=""
              loading="lazy"
            />
          </a>
        </div>
      </div>
    </div>
  );
}
export default ReachUsComponent;
