import React, { useEffect } from "react";
import Header from "./HeaderComponent";
import "../assets/css/privacy_and_refund.css";
import FooterComponent from "./FooterComponent";

/**
  Shows Privacy and Refund Policy page
*/
function PrivacyAndRefundComponent({ flag }) {
  useEffect(() => {
    const scroll_to = document.getElementById("policy_section");
    if (scroll_to) {
      scroll_to.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <div id="policy_section">
    <div className="container" >
    <div class="row">
      <div className="col-md-1 col-sm-2 col-xs-12"></div>
      <div className="col-md-10 col-sm-8 col-xs-12" id="padding_privacy_id">
      <div id="policy_header">
        <Header />
      </div>
      <div id="privacy_and_refund_container" >
          <div
            className="text-center px-2 policy_div"
            id="privacy_policy_head"
          >
            {flag ? (
              <>
                <div id="privacy_policy_main_head">Privacy Policy</div>
                <div id="policy_intro">
                  "At Aurm, we value your privacy and are committed to
                  safeguarding your personal information. This privacy policy
                  outlines how we collect, use, and protect your data while
                  using our services."
                </div>
              </>
            ) : (
              <>
                <div id="privacy_policy_main_head">Refund Policy</div>
                <div id="policy_intro">
                  "Welcome to Aurm's Refund Policy. We are dedicated to ensuring
                  a transparent and fair process for handling refunds, providing
                  you with confidence and peace of mind when using our
                  services."
                </div>
              </>
            )}
          </div>
      </div>
      <div id="privacy_container_intro">
        <div className="text-left policy_div">
          {flag ? (
            <>
              <div className="policy_section_sub_heads">
                What information do we collect and what do we use the
                information for?{" "}
              </div>
              <div className="policy_information">
                We may collect your title, name, email address, postal address,
                telephone number, mobile number, birthdate, nationality,
                identification documents, biometric information, face ID, Credit
                Rating and any other information you provide to us in writing or
                through the Internet while registering with us. The Category of
                information collected by Us can be:
              </div>
              <div className="policy_information">
                <ul className="list_of_privacy_policy_data">
                  <li>
                    Identity and Account Opening Information (e.g. name,
                    address, date of birth)
                  </li>
                  <li>
                    Contact Information (e.g. mobile number, permanent address)
                  </li>
                  <li>
                    Financial Information (e.g. Bank account number, credit
                    score, etc.)
                  </li>
                  <li>Identification Information (e.g. KYCs, PAN number)</li>
                  <li>Usage and Technical Information (e.g. IP address)</li>
                  <li>
                    Marketing and Communication Information (e.g. customer
                    support logs)
                  </li>
                  <li>Information in your device(s) (e.g. camera, OS, etc.)</li>
                </ul>
              </div>
              <div className="policy_information">
                We receive and store any information you enter on our web site
                or give us in any other way. We maintain and use your personal
                data to serve your safe deposit locker and deliver better
                services to you and to protect against fraud and also to send
                you communications containing information and updates about our
                products, services, offers, promotions and special events, and
                other marketing communications that may be of interest to you.
              </div>
              <div className="policy_information">
                Further You authorize the Company to share with Credit
                Information Companies (CIC), my personal information/details to
                procure my Credit Information to use the same to (a) provide me
                access to my Credit Information and Credit Score (including any
                changes therein) on the platform; (b) provide me with such
                products / services that I may be interested in and choose to
                avail; and/or (c) take such other actions as may be required in
                relation to the above.
              </div>
              <div className="policy_information">
                We use the information we collect about you to improve the
                content of our website, products and services, to respond to
                your inquiries and feedback and to provide you with a better
                personalized experience. We may also use the information to
                notify you about important functionality changes to the website
                and new services we think you’ll find valuable. We will retain
                and use the information only for the purposes as set out herein
                and no longer than is reasonably required for that purpose.
              </div>
              <div className="policy_information">
                While you have the option not to provide us with certain
                information or withdraw consent to collect certain information,
                kindly note that in such an event you may not be able to take
                full advantage of the entire scope of features and services
                offered to you and we reserve the right not to provide you with
                our services.
              </div>
              <div className="policy_information">
                By using our services, you hereby expressly agree and grant
                consent to the collection, use and storage of this information
                by us. Information about our customers is an important part of
                our business and we are not in the business of selling it to
                others. We reserve the right to share, disclose and transfer
                information collected hereunder with our own affiliates. In the
                event, we sell or transfer all or a portion of our business
                assets, consumer information may be one of the business assets
                that are shared, disclosed or transferred as part of the
                transaction. You hereby expressly grant consent and permission
                to us for disclosure and transfer of information to such third
                parties. You authorize us to exchange, share, part with all
                information related to the details and transaction history of
                you to our affiliates / banks / financial institutions /
                agencies/ participation in any telecommunication or electronic
                clearing network as may be required by law, customary practice,
                statistical analysis, verification or risk management and shall
                not hold us liable for use or disclosure of this information.
              </div>
              <div className="policy_information">
                Other than as set out herein, you will receive notice when
                information about you might go to third parties and you will
                have an opportunity to choose not to share the information.
                Further, we reserve the right to disclose personal information
                as obligated by law, in response to duly authorized legal
                process, governmental requests and as necessary to protect our
                rights and interests and enforce the terms and conditions of the
                products or services. If you decide to surrender your locker or
                become an inactive customer, we will continue to follow our
                privacy policy with the information we have about you. Any
                changes to our privacy policy will be posted on this page, so
                you are always aware of what information we collect, how we use
                it, how we store it and under what circumstances we disclose it.
              </div>
              <div className="policy_section_sub_heads">Third Party Links</div>
              <div className="policy_information">
                Occasionally, at our discretion, we may include or offer third
                party products or services on our website. These third-party
                sites have separate and independent privacy policies. We
                therefore have no responsibility or liability for the content
                and activities of these linked sites. Nonetheless, we seek to
                protect the integrity of our site and welcome any feedback about
                these sites. For your convenience, our website may contain links
                to other sites. We are not responsible for the privacy practices
                or the content of such websites.
              </div>
              <div className="policy_section_sub_heads">Do we use cookies?</div>
              <div className="policy_information">
                We use cookies placed on your computer’s hard drive through your
                Web browser to enable our systems to recognize your browser
                during your visit to our site or app and when you return to our
                site. Whenever you use this website, information may be
                collected using cookies and other technologies. By using this
                website or application, you agree to Company's use of cookies as
                described here. However, you can control and manage cookies in
                various ways. Please bear in mind that removing or blocking
                cookies can impact user experience and parts of this website may
                no longer be fully accessible.
              </div>
              <div className="policy_section_sub_heads">Grievance Officer</div>
              <div className="policy_information">
                The grievance officer designated hereunder shall address any
                grievance that you may have regarding our privacy policy.
                <br />
                Name: Data Privacy and Policy Officer <br />
                E-mail:{" "}
                <a href="#" className="text-decoration-underline">
                  dppo@aurm.in
                </a>
              </div>
              <div className="policy_section_sub_heads">
                Changes to Privacy Policy
              </div>
              <div className="policy_information">
                We may update this Privacy Policy to reflect changes to our
                information practices. If we make any material changes we will
                notify you by email (sent to the e-mail address specified in
                your account) prior to the change becoming effective.
              </div>
              <div className="policy_section_sub_heads">Your Consent</div>
              <div className="policy_information">
                By using our site, engaging with our service, you consent to our
                privacy policy.
              </div>
            </>
          ) : (
            <>
              <div className="policy_section_sub_heads">Introduction </div>
              <div className="policy_information">
                Lorem ipsum dolor sit amet, esse audire neglegentur at sed, ei
                mel ridens gubergren. An quo fierent mnesarchum. Id cum exerci
                delicata, te eos zril efficiendi, an cum eligendi suavitate. Et
                feugiat legimus his, vim animal similique conceptam ut. In
                dictas oblique iuvaret mel, ne qui invidunt honestatis.
              </div>
              <div className="policy_information">
                Sed paulo suscipit moderatius id, mutat qualisque id mel, ne eos
                hinc dicant. Has graeco inimicus et. Ei eam mundi everti
                argumentum. Mei te paulo scriptorem, enim repudiandae vel ad.
                Minimum invidunt quo at, an velit alienum signiferumque est. Ad
                mollis tibique voluptaria est, cum ei hendrerit cotidieque.
              </div>
              <div className="policy_information">
                Nam qualisque definiebas te. His audire intellegat id, cum no
                impetus nominati mediocritatem, at his erant option alterum. Eum
                novum mucius no. Duo et quis tollit aliquip, ipsum dicat utroque
                nam ut, eum ex aliquip consulatu euripidis.
              </div>
              <div className="policy_information">
                Eum at iuvaret incorrupte, ea sea mundi aperiam feugait. Nam
                case suscipit rationibus cu, cibo albucius lucilius sea eu. Ut
                oratio conclusionemque per. Ornatus atomorum cum te. Te augue
                placerat pro, saepe possit detracto ad sed. Clita graeco
                rationibus est cu, his unum vidisse concludaturque no.
              </div>
              <div className="policy_information">
                Usu illud expetenda suscipiantur ea, ad vel minim pertinacia, ei
                vix amet inciderint. Sed ea suas epicurei, eam augue zril
                assueverit ad. Sit ea recteque mediocrem intellegebat, ut nam
                audire maluisset mediocritatem. Epicurei repudiare qui cu.
              </div>
              <div className="policy_section_sub_heads mt-5">Section</div>
              <div className="policy_information">
                Lorem ipsum dolor sit amet, esse audire neglegentur at sed, ei
                mel ridens gubergren. An quo fierent mnesarchum. Id cum exerci
                delicata, te eos zril efficiendi, an cum eligendi suavitate. Et
                feugiat legimus his, vim animal similique conceptam ut. In
                dictas oblique iuvaret mel, ne qui invidunt honestatis.
              </div>
              <div className="policy_information">
                Sed paulo suscipit moderatius id, mutat qualisque id mel, ne eos
                hinc dicant. Has graeco inimicus et. Ei eam mundi everti
                argumentum. Mei te paulo scriptorem, enim repudiandae vel ad.
                Minimum invidunt quo at, an velit alienum signiferumque est. Ad
                mollis tibique voluptaria est, cum ei hendrerit cotidieque.
              </div>
              <div className="policy_information">
                Nam qualisque definiebas te. His audire intellegat id, cum no
                impetus nominati mediocritatem, at his erant option alterum. Eum
                novum mucius no. Duo et quis tollit aliquip, ipsum dicat utroque
                nam ut, eum ex aliquip consulatu euripidis.
              </div>
              <div className="policy_information">
                Eum at iuvaret incorrupte, ea sea mundi aperiam feugait. Nam
                case suscipit rationibus cu, cibo albucius lucilius sea eu. Ut
                oratio conclusionemque per. Ornatus atomorum cum te. Te augue
                placerat pro, saepe possit detracto ad sed. Clita graeco
                rationibus est cu, his unum vidisse concludaturque no.
              </div>
              <div className="policy_information">
                Usu illud expetenda suscipiantur ea, ad vel minim pertinacia, ei
                vix amet inciderint. Sed ea suas epicurei, eam augue zril
                assueverit ad. Sit ea recteque mediocrem intellegebat, ut nam
                audire maluisset mediocritatem. Epicurei repudiare qui cu.
              </div>
            </>
          )}
        </div>
      </div>
      </div>
      <div className="col-md-1 col-sm-2 col-xs-12"></div>
      </div>
      </div>
      <div className="privacy_footer" id="privacy_footer">
        <FooterComponent />
      </div>
    </div>
  );
}

export default PrivacyAndRefundComponent;
