import React from 'react';
import "../assets/css/blog_post.css";
import blog_post_arrow from "../assets/images/blog_post_arrow.svg";

/**
  Shows the blog post.
*/
function BlogPostComponent(props) {
    const { id, image, webp_image, title, publisherImage, publisherWebpImage, publishedBy, publishedAt } = props;
    return (
        <div id='blog_post_section'>
            <a href={"/individual_blogs/"+id}><div className='card blog_post_card'>
                <picture>
                    <source srcSet={webp_image} type="image/webp" />
                    <img className='blogs-image-class w-100' src={image} alt="BlogPost" loading="lazy" />
                </picture>
            </div></a>
            <a href={"/individual_blogs/"+id}><div className='blog_post_title text-white text-left mt-3'>{title.length > 48 ? title.substring(0, 48) + "..." : title}<span className='blog_post_arrow'><img src={blog_post_arrow} alt="Arrow" /></span></div></a>
            <div className='d-flex image_thumbnail_or_name'>
                <div className='blog_post_publicher_image' >
                    <picture>
                        <source srcSet={publisherWebpImage} type="image/webp" />
                        <img src={publisherImage} alt="BlogPost_Publisher" style={{ width: '40px', height: '40px', borderRadius: '9999px', border: '0.75px' }} loading="lazy" />
                    </picture>
                </div>
                <div className='blog_post_publisher_name blog_post_info_published_color'>
                    {publishedBy}<br /><span className='blog_post_published_on'>{publishedAt}</span>
                </div>
            </div>
        </div>
    )
}

export default React.memo(BlogPostComponent);