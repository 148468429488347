import React, { useEffect, useRef, useState } from "react";
import { Modal } from "bootstrap";
import "../assets/css/gallery.css";
import video_play_btn from "../assets/images/video_play_btn.svg";
import cross_icon from "../assets/images/cross_icon.svg";

const gallery_poster_1 = process.env.PUBLIC_URL + process.env.REACT_APP_GALLERY_COMPONENT_1_POSTER;
const gallery_poster_2 = process.env.PUBLIC_URL + process.env.REACT_APP_GALLERY_COMPONENT_2_POSTER;
const gallery_poster_3 = process.env.PUBLIC_URL + process.env.REACT_APP_GALLERY_COMPONENT_3_POSTER;
const gallery_poster_4 = process.env.PUBLIC_URL + process.env.REACT_APP_GALLERY_COMPONENT_4_POSTER;

const gallery_poster_1_webp = process.env.PUBLIC_URL + process.env.REACT_APP_GALLERY_COMPONENT_1_POSTER_WEBP;
const gallery_poster_2_webp = process.env.PUBLIC_URL + process.env.REACT_APP_GALLERY_COMPONENT_2_POSTER_WEBP;
const gallery_poster_3_webp = process.env.PUBLIC_URL + process.env.REACT_APP_GALLERY_COMPONENT_3_POSTER_WEBP;
const gallery_poster_4_webp = process.env.PUBLIC_URL + process.env.REACT_APP_GALLERY_COMPONENT_4_POSTER_WEBP;

const gallery_component_1_type = process.env.REACT_APP_GALLERY_COMPONENT_1_TYPE;
const gallery_component_2_type = process.env.REACT_APP_GALLERY_COMPONENT_2_TYPE;
const gallery_component_3_type = process.env.REACT_APP_GALLERY_COMPONENT_3_TYPE;
const gallery_component_4_type = process.env.REACT_APP_GALLERY_COMPONENT_4_TYPE;

/**
    Shows Aurm-In-actions section.
*/
function GalleryComponent() {
  const [videoSource, setVideoSource] = useState('https://pub-assets.aurm.in/aurm_promo_video_v1.mp4');
  const [videoPlaying, setVideoPlaying] = useState(false);
  const watchVideoRef = useRef(null);
  const modalRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);


  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    const watchVideoHandler = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!videoPlaying && watchVideoRef.current) {
            if (watchVideoRef.current.paused) {
              watchVideoRef.current
                .play()
                .then(() => {
                  setVideoPlaying(true);
                })
                .catch((error) => {
                  console.error("Error playing video:", error);
                });
            }
          }
        } else {
          if (videoPlaying && watchVideoRef.current) {
            if (!watchVideoRef.current.paused) {
              try {
                watchVideoRef.current.pause();
              } catch (error) {
                console.error("Error pausing video:", error);
              }
              setVideoPlaying(false);
            }
          }
        }
      });
    };

    const observer = new IntersectionObserver(watchVideoHandler, options);
    const section = document.getElementById("watch_video");
    if (section) {
      observer.observe(section);
    }
    return () => {
      observer.disconnect();
    };
  }, [videoPlaying]);

  useEffect(() => {
    // Handle body class for blur effect
    const sectionSelectors = ['header', '#watch_video_container', '#landing_section', '#feature_section', '#team_section', '#blogs_list_section',
       '#partner_with_us_section', '#gallery_section_container', '#testimonial_main_section', '#reach_us_section', '#contact_section', '#faqs_main_section', '#footer_section', '#our_story_section_container'];

    // Handle class for blur effect on each section
    sectionSelectors.forEach((selector) => {
      const sectionElement = document.querySelector(selector);
      if (sectionElement) {
        if (modalOpen) {
          sectionElement.classList.add('blur-background');
        } else {
          sectionElement.classList.remove('blur-background');
        }
      }
    });
  }, [modalOpen]);


  const openVideoModal = () => {
    setModalOpen(true);
    const modal = new Modal(modalRef.current, {
      backdrop: "static",
      keyboard: false,
    });
    modal.show();
  };

  const closeModal = () => {
    setModalOpen(false);
    const modal = Modal.getInstance(modalRef.current);
    if (modal) {
      modal.hide();
      setVideoPlaying(false);
      // Pause the video when the modal is closed
      if (watchVideoRef.current && !watchVideoRef.current.paused) {
        watchVideoRef.current.pause();
      }
      if (watchVideoRef.current) {
        watchVideoRef.current.currentTime = 0;
      }
    }
  };

  const handlePlayButtonClick = (video_id) => {
    let videoUrl = process.env.REACT_APP_GALLERY_COMPONENT_1_VIDEO;
    if (video_id === 2) {
      videoUrl = process.env.REACT_APP_GALLERY_COMPONENT_2_VIDEO;
    } else if (video_id === 3) {
      videoUrl = process.env.REACT_APP_GALLERY_COMPONENT_3_VIDEO;
    } else if (video_id === 4) {
      videoUrl = process.env.REACT_APP_GALLERY_COMPONENT_4_VIDEO;
    }
    setVideoSource(videoUrl);
    // If the video is playing, restart it with the new source
    if (videoPlaying) {
      watchVideoRef.current.pause().then(() => {
        setVideoPlaying(false);
      })
    }

    // Open the modal
    openVideoModal();
    // Play the video manually when the play button is clicked
    if (watchVideoRef.current && watchVideoRef.current.paused) {
      watchVideoRef.current
        .play()
        .then(() => {
          setVideoPlaying(true);
        })
        .catch((error) => {
          console.error("Error playing video:", error);
        });
    }
  };
  return (
    <div id='gallery_section'>
      <div className="container aurm_actions_container" id="gallery_section_container">
        <div className="aurm_actions_heading text-center"><span className="text-white">See Aurm in </span><span className="aurm_actions_action_text_color">Action</span></div>
        <div className="aurm_actions_cards">
          <div className="row">
            <div className="col-md-6">
              <div className="pe-1">
                <div className="card aurm_actions_cards aurm_actions_cards_margin">
                  {gallery_component_1_type === 'video' ? (
                    <div>
                      <picture>
                        <source srcSet={gallery_poster_1_webp} type="image/webp" />
                        <img className='aurm_action_images aurm_action_image_translucent' src={gallery_poster_1} alt='action' loading="lazy" />
                      </picture>
                      <img src={video_play_btn} className='gallery_video_play_btn' onClick={() => handlePlayButtonClick(1)} alt="play_btn"/>
                    </div>
                  ) : (
                    <div>
                      <picture>
                        <source srcSet={gallery_poster_1_webp} type="image/webp" />
                        <img className='aurm_action_images' src={gallery_poster_1} alt='action' loading="lazy" />
                      </picture>
                    </div>
                  )
                  }
                </div>
                <div className="card aurm_actions_cards">
                  {gallery_component_3_type === 'video' ? (
                    <div>
                      <picture>
                        <source srcSet={gallery_poster_2_webp} type="image/webp" />
                        <img className='aurm_action_images aurm_action_image_translucent' src={gallery_poster_3} alt='action' loading="lazy" />
                      </picture>
                      <img src={video_play_btn} className='gallery_video_play_btn' onClick={() => handlePlayButtonClick(3)} alt="gallery_btn" loading="lazy" />
                    </div>
                  ) : (
                    <div>
                      <picture>
                        <source srcSet={gallery_poster_2_webp} type="image/webp" />
                        <img className='aurm_action_images' src={gallery_poster_3} alt='action' loading="lazy" />
                      </picture>
                    </div>
                  )
                  }
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="ps-1">
                <div className="card aurm_actions_cards aurm_actions_cards_margin" id="gallery_poster_2">
                  {gallery_component_2_type === 'video' ? (
                    <div>
                      <picture>
                        <source srcSet={gallery_poster_3_webp} type="image/webp" />
                        <img className='aurm_action_images aurm_action_image_translucent' src={gallery_poster_2} alt='action' loading="lazy" />
                      </picture>
                      <img src={video_play_btn} className='gallery_video_play_btn' onClick={() => handlePlayButtonClick(2)} alt="play_btn" loading="lazy" />
                    </div>
                  ) : (
                    <div>
                      <picture>
                        <source srcSet={gallery_poster_3_webp} type="image/webp" />
                        <img className='aurm_action_images' src={gallery_poster_2} alt='action' loading="lazy" />
                      </picture>
                    </div>
                  )
                  }
                </div>
                <div className="card aurm_actions_cards">
                  {gallery_component_4_type === 'video' ? (
                    <div>
                      <picture>
                        <source srcSet={gallery_poster_4_webp} type="image/webp" />
                        <img className='aurm_action_images aurm_action_image_translucent' src={gallery_poster_4} alt='action' loading="lazy" />
                      </picture>
                      <img src={video_play_btn} className='gallery_video_play_btn' onClick={() => handlePlayButtonClick(4)} alt="play_btn" loading="lazy" />
                    </div>
                  ) : (
                    <div>
                      <picture>
                        <source srcSet={gallery_poster_4_webp} type="image/webp" />
                        <img className='aurm_action_images' src={gallery_poster_4} alt='action' loading="lazy" />
                      </picture>
                    </div>
                  )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" ref={modalRef}>
        <div className="modal-dialog modal-xl watch-video-modal-class modal-dialog-centered bg-transparent video_model">
          <div className="modal-content video_modal_content">
            <div className="modal-body bg-none">
              <div id="video_close_button">
                <div
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={closeModal}
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    width: 40,
                    height: 40,
                    left: 0,
                    top: 0,
                    background: "rgba(255, 255, 255, 0.20)",
                    borderRadius: 9999,
                  }}
                >
                  <img src={cross_icon} alt="cross_icon" loading="lazy"/>
                </div>
              </div>

              <video
                width="100%"
                height="100%"
                controls
                onPause={() => setVideoPlaying(false)}
                onEnded={() => setVideoPlaying(false)}
                ref={watchVideoRef}
              >
                <source
                  src={videoSource}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default GalleryComponent;