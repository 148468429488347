import React, {useState,useEffect } from "react";
import "../assets/css/blogs_list.css";
import BlogPostComponent from "./BlogPostComponent";
const view_all_blogs_url = process.env.REACT_APP_BLOGS_URL;

/**
 * Shows the blog post Section.
 */

function BlogsListComponent(props) {
  // Blog posts data
  const [blogsJsonData, setBlogsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://pub-assets.aurm.in/json%2Fblog_v1.json');
        const data = await response.json();
        const blogsJsonData = data.blogs;
        setBlogsData(blogsJsonData)
      }catch (error) {
        console.error('Error fetching data:', error);
      }
    } 
    fetchData();
  }, []);
  
  const blogPosts = blogsJsonData;
  const displayedPosts =
    props.list_show_type === "all_blogs" ? blogPosts : blogPosts.slice(0, 3);

  return (
    <div id="blogs_list_section">
      <div className="container">
      {props.list_show_type !== "all_blogs" ? (
          <div>
            {props.is_individual_page === "true" ? (
              <div className="blog_list_more_blogs">
                More Blogs
              </div>
              
            ) : (
              <div>
                <div className="blogs_list_primary_text mt-2 text-center">
                  <span className="text-white">Latest from the </span>
                  <span className="blogs_list_blog_text_color">blog</span>
                </div>
                <div className="blogs_list_secondary_text text-center mt-3">
                  Articles, tips, guides, industry best practices, and news.
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            <div className="blogs_list_primary_text text-white text-center blog_title">
              Blogs
            </div>
          </div>
        )}

        <div id="blogs_list_container" className="m-auto">
          <div className="row justify-content-center">
            <div className="row justify-content-center">
              {displayedPosts.map((blogPost, index) => (
                <div
                  key={index}
                  className="col-md-4 col-sm-6 col-xs-12 blog_post_cards_div"
                >
                  <BlogPostComponent {...blogPost} />
                </div>
              ))}
            </div>
            <div className="mt-3 text-center">
              {props.list_show_type !== "all_blogs" && (
                <div>
                  {props.is_individual_page === "true" ? (
                <a href={`${view_all_blogs_url}`}>
                  <button className="blogs_list_view_all_post_button text-white">
                    View all posts
                  </button>
                </a>
                ) : (
                  <a href={`${view_all_blogs_url}`}>
                  <button className="blogs_list_view_all_post_button_home text-white">
                    View all posts
                  </button>
                </a>
                )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogsListComponent;
