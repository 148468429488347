import React from "react";
import "../assets/css/aurm_behind.css";
import arrow_img from "../assets/images/arrow-narrow-right.svg";

/**
  Shows the Story Behind Aurm Section.
*/
function AurmBehindArumComponent() {
  return (
    <section id="aurm_behind_section">
      <div className="container-fluid p-0" id="aurm_behind_main">
        <div className="container m-auto text-center" id="story_container">
          <div className="story_head d-flex justify-content-center">
            <div id="story_aurm_text"> The story behind Aurm </div>
            <a href="/our_story" className="story_behind_button ms-4">
              <div className="arrow-narrow-right">
                <img className="icon aurm_behind_arrow_icon" src={arrow_img} alt="arrow" loading="lazy" />
              </div>
            </a>
          </div>
          <div id="story_sub_text">
            What we stand for, and the core of our being
          </div>
        </div>
      </div>
    </section>
  );
}

export default AurmBehindArumComponent;
