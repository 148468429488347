import React, { useEffect }  from "react";
import "../assets/css/global_styles.css";
import "../assets/css/blogs_home_page.css";
import "../assets/css/blogs_list.css";
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import BlogsListComponent from "./BlogsListComponent";

/**
  Loads Our Blogs Page
*/
function BlogsPageComponent() {
  useEffect(() => {
    const scroll_to = document.getElementById("blogs_page_div");
    if (scroll_to) {
      scroll_to.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <div id="blogs_page_div">
      <div id="blogs_header">
        <HeaderComponent />
      </div>
      <BlogsListComponent list_show_type="all_blogs" />
      <div id="blogs_footer">
        <FooterComponent />
      </div>
    </div>
  );
}

export default BlogsPageComponent;
