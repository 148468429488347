import React from "react";
import "../assets/css/team.css";
import TeamCardComponent from "./TeamCardComponent";
import dots from "../assets/images/dots.svg";
import AurmBehindComponent from "./AurmBehindComponent";
import ComparisonMatrixComponent from "./ComparisionMatrixComponent";

/**
  Shows the team section
*/
function TeamComponent() {

  const team_member1 = {
    PersonImage: "https://pub-assets.aurm.in/teamimages%2FPeople_Card_v2_Vijay.svg",
    PersonImageWebp:"https://pub-assets.aurm.in/teamimages%2Fteamimages_People_Card_v2_Vijay.webp",
    PersonName: "Vijay Arisetty",
    PersonDesignation: "Founder & CEO",
    LinkedinLink: "https://in.linkedin.com/in/a-vijay-kumar",
    PersonDescription:
     "Ex-CEO of MyGate and an Indian Air Force Shaurya Chakra awardee, Vijay is Aurm’s creator",
  };

  const team_member2 = {
    PersonImage: "https://pub-assets.aurm.in/teamimages%2FPeople_Card_v2_Suraj.svg",
    PersonImageWebp:"https://pub-assets.aurm.in/teamimages%2Fteamimages_People_Card_v2_Suraj.webp",
    PersonName: "Suraj HS",
    PersonDesignation: "Co-Founder & Head of Growth",
    LinkedinLink: "https://linkedin.com/in/suraj-h-s-a789108a",
    PersonDescription:
      "Having led strategic growth initiatives at MyGate, Suraj has a thorough and deep understanding of residential community security",
  };
  
  const team_member5 = {
    PersonImage: "https://pub-assets.aurm.in/teamimages%2FPeople_Card_v2_Pratap.svg",
    PersonImageWebp:"https://pub-assets.aurm.in/teamimages%2Fteamimages_People_Card_v2_Pratap.webp",
    PersonName: "Pratap Chandana",
    PersonDesignation: "Co-Founder & Head of Tech",
    LinkedinLink: "",
    PersonDescription:
      "He built MyGate’s core infra/data tech stack, and now Pratap is the architect of the security, scalability and Aurm’s technology.",
  };

  return (
    <section id="team_section">
      <div className="container-fluid p-0" id="team_container_div">
        <div className="container">
          <div className="text-left" id="team_section_dots">
            <img className="team_section_dots_img" src={dots} alt="dots" />
          </div>
          <div className="text-end" id="middle_dots">
            <img className="team_section_dots_img" src={dots} alt="dots" />
          </div>
          <div className="container" id="team_container">
            <div className="teams-safe-hands">
              <div className="text-center" id="team_section_title">
                You’re in <span id="safe_text">safe</span> hands
              </div>
              <div
                className="blue_text_color text-center m-auto"
                id="team_section_subtitle"
              >
                With a founding team that brings together the experience of Armed
                Forces, residential security and premium brands, we know what it
                takes to provide a secure, private and luxurious experience as you
                access your wealth.
              </div>
            </div>
            <div className="container col-md-12" id="team_card_row">
              <div className="row m-auto justify-content-center team_card_content_row">
                <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 team_cards">
                  <TeamCardComponent {...team_member1} />
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 team_cards">
                  <TeamCardComponent {...team_member2} />
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 team_cards">
                  <TeamCardComponent {...team_member5} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AurmBehindComponent />
      <ComparisonMatrixComponent />
    </section>
  );
}

export default TeamComponent;