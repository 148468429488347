import React from "react";
import "../assets/css/home.css";
import "../assets/css/global_styles.css";
import LandingComponent from "./LandingComponent";
import FeaturesComponent from "./FeaturesComponent";
import CommitmentStardardComponent from "./CommitmentStandardComponent";
import WatchVideoComponent from "./WatchVideoComponent";
import TeamComponent from "./TeamComponent";
import BlogsListComponent from "./BlogsListComponent";
import PartnerWithUsComponent from "./PartnerWithUsComponent";
import GalleryComponent from "./GalleryComponent";
import ReachUsComponent from "./ReachUsComponent";
import { useEffect } from "react";
import { scroller } from "react-scroll";
import ContactUSComponent from "./ContactUsComponent";
import FaqsComponent from "./FaqsComponent";
import FooterComponent from "./FooterComponent";
import FeaturedInVComponent from "./FeaturedInComponent";

/**
  Combines all the components of the website into a single page
*/
function HomeComponent() {
  const scrollToSection = (sectionName) => {
    const scroller_section = document.getElementById(sectionName);
    if (scroller_section) {
      scroller.scrollTo(sectionName, {
        duration: 50,
        smooth: true,
      });
    }
    return true;
  };

  useEffect(() => {
    setTimeout(() => {
      const section_element = localStorage.getItem("scrollToSection");
      if (section_element) {
        const isscrolled = scrollToSection(section_element);
        if (isscrolled) {
          localStorage.removeItem("scrollToSection");
        }
      }
    }, 1000);

  }, []);
  return (
    <div id="home_page">
      <LandingComponent />
      <FeaturesComponent />
      <WatchVideoComponent videoBg={"video_section_main"} homePage={true}/>
      <CommitmentStardardComponent />
      <TeamComponent />
      <FeaturedInVComponent />
      <BlogsListComponent />
      <PartnerWithUsComponent />
      <GalleryComponent />
      <ReachUsComponent />
      <ContactUSComponent />
      <FaqsComponent />
      <FooterComponent />
    </div>
  );
}

export default HomeComponent;