import React, { useEffect, useRef, useState } from "react";
import { Modal } from "bootstrap";
import "../assets/css/watch_video.css";
import dot from "../assets/images/dot.svg";
import play_button from "../assets/images/play_button.svg";
import cross_icon from "../assets/images/cross_icon.svg";
import video_layer_top from "../assets/images/video_layer_top.svg";
import video_layer_bottom from "../assets/images/video_layer_bottom.svg";

function WatchVideoComponent(props) {
  const videoBg = props.videoBg ? props.videoBg : "";
  const homePage = props.homePage ? props.homePage : false;
  const ourstory_first_vd = props.ourstory_first_vd ? props.ourstory_first_vd : false;
  const ourstory_second_vd = props.ourstory_second_vd ? props.ourstory_second_vd : false;
  const [videoPlaying, setVideoPlaying] = useState(false);
  const watchVideoRef = useRef(null);
  const modalRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    const watchVideoHandler = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!videoPlaying && watchVideoRef.current) {
            if (watchVideoRef.current.paused) {
              watchVideoRef.current
                .play()
                .then(() => {
                  setVideoPlaying(true);
                })
                .catch((error) => {
                  console.error("Error playing video:", error);
                });
            }
          }
        } else {
          if (videoPlaying && watchVideoRef.current) {
            if (!watchVideoRef.current.paused) {
              try {
                watchVideoRef.current.pause();
              } catch (error) {
                console.error("Error pausing video:", error);
              }
              setVideoPlaying(false);
            }
          }
        }
      });
    };

    const observer = new IntersectionObserver(watchVideoHandler, options);
    const section = document.getElementById("watch_video");
    if (section) {
      observer.observe(section);
    }
    return () => {
      observer.disconnect();
    };
  }, [videoPlaying]);
  useEffect(() => {
    // Handle body class for blur effect
    const sectionSelectors = ['header', '#watch_video_container', '#landing_section', '#feature_section', '#team_section', '#blogs_list_section',
       '#partner_with_us_section', '#gallery_section', '#testimonial_main_section', '#reach_us_section', '#contact_section', '#faqs_main_section', '#footer_section'];

    // Handle class for blur effect on each section
    sectionSelectors.forEach((selector) => {
      const sectionElement = document.querySelector(selector);
      if (sectionElement) {
        if (modalOpen) {
          sectionElement.classList.add('blur-background');
        } else {
          sectionElement.classList.remove('blur-background');
        }
      }
    });
  }, [modalOpen]);


  const openVideoModal = () => {
    setModalOpen(true);
    const modal = new Modal(modalRef.current, {
      backdrop: "static",
      keyboard: false,
    });
    modal.show();
  };

  const closeModal = () => {
    setModalOpen(false);
    const modal = Modal.getInstance(modalRef.current);
    if (modal) {
      modal.hide();
      setVideoPlaying(false);
      // Pause the video when the modal is closed
      if (watchVideoRef.current && !watchVideoRef.current.paused) {
        watchVideoRef.current.pause();
      }
      if (watchVideoRef.current) {
        watchVideoRef.current.currentTime = 0;
      }
    }
  };

  const handlePlayButtonClick = () => {
    openVideoModal();
    // Play the video manually when the play button is clicked
    if (watchVideoRef.current && watchVideoRef.current.paused) {
      watchVideoRef.current
        .play()
        .then(() => {
          setVideoPlaying(true);
        })
        .catch((error) => {
          console.error("Error playing video:", error);
        });
    }
  };

  const posterImage = ourstory_first_vd
    ? "https://pub-assets.aurm.in/featuredin_images%2Fourstory_video_poster.webp"  // Example image URL for the first video
    : ourstory_second_vd
    ? "https://pub-assets.aurm.in/main_video_poster.webp" // Example image URL for the second video
    : "https://pub-assets.aurm.in/main_video_poster.webp"; // Default image


  return (
    <section id="watch_video_section" className={videoBg}>
      <div className="container-fluid p-0" id="watch_video_container">
        {homePage ? (
          <>
            <div className="text-center" id="watch_video_text">
              <span id="video_title">Watch <span id="watch_video_sub_text">how it works</span></span>
            </div>

          </>
        ) : (
          <>
            <div className="text-center" id="watch_video_text">
              <span id="video_title">Check out <span id="watch_video_sub_text">our demo video</span></span>
            </div>
          </>
        )}
        <div className="video_poster_div position-relative">
          <img width="100%" preload="auto" src={posterImage} alt="ajs" loading="lazy" />
          {homePage ? (
            <>
              <img id="video_top_layer" src={video_layer_top} alt="" loading="lazy"/>
              <img id="video_bottom_layer" src={video_layer_bottom} alt="" loading="lazy"/>
            </>
          ) : (
            <>
              <img id="video_top_layer" src="https://pub-assets.aurm.in/video_layer_top_alt.svg" alt="" loading="lazy"/>
              {/* <img id="video_bottom_layer" src="https://pub-assets.aurm.in/video_layer_bottom_alt.svg" /> */}
            </>
          )}

          <div className="play_button_overlay">
            <button
              className="btn"
              id="play_button"
              onClick={handlePlayButtonClick}>
              <img id="play_button_svg" src={play_button} alt="" />
            </button>
          </div>
        </div>   
        <div className="container d-flex justify-content-center eligibility_btn_div position-relative bg-transparent">
          <img src={dot} alt="dot" className="button_dot watch_btn_dot" />
          <div>
           <a rel="noreferrer" href="https://api.whatsapp.com/send/?phone=919740741016&text=I'm intrigued about Aurm. Tell me more! &type=phone_number&app_absent=0" target="_blank">
            <button className="btn btn-primary " id="eligibility_btn">
              Check your eligibility
            </button>
           </a>
          </div>
          <img src={dot} alt="dot" className="button_dot watch_btn_dot"/>
        </div>
        
      </div>
       {/* Bootstrap modal for the video */}
      <div className="modal fade" ref={modalRef}>
          <div className="modal-dialog modal-xl watch-video-modal-class modal-dialog-centered bg-transparent video_model">
            <div className="modal-content video_modal_content">
              <div className="modal-body bg-none">
                <div id="video_close_button">
                  <div
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeModal}
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      width: 40,
                      height: 40,
                      left: 0,
                      top: 0,
                      background: "rgba(255, 255, 255, 0.20)",
                      borderRadius: 9999,
                    }}
                  >
                    <img src={cross_icon} alt=""/>
                  </div>
                </div>

                <video
                  width="100%"
                  height="100%"
                  controls
                  onPause={() => setVideoPlaying(false)}
                  onEnded={() => setVideoPlaying(false)}
                  ref={watchVideoRef}
                >
                {ourstory_first_vd ? (
                  <source
                    src="https://pub-assets.aurm.in/Aurm_Final_Brand_Manifesto.mp4"
                    type="video/mp4"
                    />):
                      <source
                    src="https://pub-assets.aurm.in/aurm_promo_video_v1.mp4"
                    type="video/mp4"
                    />
                    }
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
    </section>
  );
}

export default WatchVideoComponent;
