import React, { useEffect, useState } from "react";
import Header from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import "../assets/css/terms_and_conditions.css";

/**
  Shows Terms and Conditons page
*/
function TermsAndConditionsComponent({ flag }) {
  const [hideHeader, setHideHeader] = useState(false);
  const [hideFooter, setHideFooter] = useState(false);
  const [isFAQ, handleFAQVisibility] = useState(false);

  useEffect(() => {
    const scroll_to = document.getElementById("terms_section");
    if (scroll_to) {
      scroll_to.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    // Parse URL parameters on component mount
    const urlParams = new URLSearchParams(window.location.search);
    const hideFooter = urlParams.get('hideFooter');
    const hideHeader = urlParams.get('hideHeader');
    const hideChatButton = urlParams.get('hideChatButton');
    const isFAQ = urlParams.get('isFAQ');
    if (hideFooter === 'true') {
      setHideFooter(true);
    }
    if(hideHeader === 'true') {
      setHideHeader(true);
    }
    if(hideChatButton === 'true') {
        window.fcWidgetMessengerConfig = {
          config: {
            headerProperty:{
              hideChatButton: true,
            }
          }
        }
    }
    if(isFAQ === 'true') {
      window.fcWidgetMessengerConfig = {
        open: true,
      }
      handleFAQVisibility(true);
    }
  }, []);

  return (
   !isFAQ && <div id="terms_section">
     { !hideHeader && 
      <div id="terms_header">
        <Header />
      </div>}
      <div className="container" id="terms_container">
        <div className="col-md-12">
          <div className="col-md-6 m-auto text-center px-2 terms_div">
            <div className="terms_published">Published 08 Nov 2023</div>
            <div id="terms_head">TERMS & CONDITIONS</div>
          </div>
        </div>
      </div>
      <div className="container" id="terms_container_intro">
        <div className="col-md-6 m-auto text-left terms_div">
          <div className="terms_section_sub_heads">
            1. &nbsp;
            <span className="text-underline text-decoration-underline">
              Definitions
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">1.1.&nbsp;</span>
            <span>
              In these Terms and “Conditions”, the following definitions apply.
            </span>
          </div>
          <ol type="a" className="custom-ordered-list text-left">
            <div className="terms d-flex terms_list_text">
              <span className="me-3">a.&nbsp;</span>
              <span>
                Lease Agreement: These conditions, the application form, and the
                price list.
              </span>
            </div>
            <div className="terms d-flex terms_list_text">
              <span className="me-3">b.&nbsp;</span>
              <span>
                Locker: The safe deposit locker hired by the lessee pursuant to
                this agreement as identified in the application form.
              </span>
            </div>
            <div className="terms d-flex terms_list_text">
              <span className="me-3">c.&nbsp;</span>
              <span>
                Lessee: Any natural person or body corporate or other entity
                identified as the lessee in the
              </span>
            </div>
            <div className="terms d-flex terms_list_text">
              <span className="me-3">d.&nbsp;</span>
              <span>application form.</span>
            </div>
            <div className="terms d-flex terms_list_text">
              <span className="me-3">e.&nbsp;</span>
              <span>
                Deposit: A sum of money paid at the start of the term,
                refundable as set out in these conditions, the amount of which
                is set out in the application form.
              </span>
            </div>
            <div className="terms d-flex terms_list_text">
              <span className="me-3">f.&nbsp;</span>
              <span>
                Rent: The rent charged by us for the lease of the locker as set
                out in this agreement.
              </span>
            </div>
            <div className="terms d-flex terms_list_text">
              <span className="me-3">g.&nbsp;</span>
              <span>
                Nominee: A person nominated by you to have access to the locker.
              </span>
            </div>
            <div className="terms d-flex terms_list_text">
              <span className="me-3">h.&nbsp;</span>
              <span>
                Notice: Written communication by either us or you served in
                accordance with these conditions.
              </span>
            </div>
            <div className="terms d-flex terms_list_text">
              <span className="me-3">i.&nbsp;</span>
              <span>
                Price List: A list of rent and other charges payable in respect
                of the locker and additional services (as amended from time to).
              </span>
            </div>
            <div className="terms d-flex terms_list_text">
              <span className="me-3">j.&nbsp;</span>
              <span>
                Privacy Policy: Privacy policy of the Company available on the
                website of the Company.
              </span>
            </div>
            <div className="terms d-flex terms_list_text">
              <span className="me-3">k.&nbsp;</span>
              <span>
                Start Date: The start date as stated in the application form.
              </span>
            </div>
            <div className="terms d-flex terms_list_text">
              <span className="me-3">l.&nbsp;</span>
              <span>
                Term: The initial period of 12 months or such additional period
                as set out in the application form, commencing on the start
                date, as extended from time to time in accordance with this
                agreement.
              </span>
            </div>
            <div className="terms d-flex terms_list_text">
              <span className="me-3">m.&nbsp;</span>
              <span>
                ‘We’, ‘Us’, ‘Our’, ‘The Company’ viz., Pransu Technologies
                Private Limited.
              </span>
            </div>
            <div className="terms d-flex terms_list_text">
              <span className="me-3">n.&nbsp;</span>
              <span>
                ‘You’, ‘Your’: The lessee named in the application form.
              </span>
            </div>
          </ol>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">1.2.&nbsp;</span>
            <span>
              These Conditions, together with the application form, Privacy
              policy and Price List, form the Lease Agreement under which We
              agree to provide You the use of the Locker for the Term.
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">1.3.&nbsp;</span>
            <span>
              These Conditions shall apply to the Lease Agreement and will
              govern the entire relationship between Us and You throughout the
              Term.
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">1.4.&nbsp;</span>
            <span>
              The formation of the Lease Agreement is conditional on You
              providing Us with such evidence of identity as We may require, our
              acceptance of the information provided by You in the application
              form and the payment by You of the Rent and Deposit. You may not
              open a Locker in the name of or on behalf of any third party.
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">1.5.&nbsp;</span>
            <span>
              You may only appoint one Nominee at any time, and You may remove
              such Nominee by Notice to Us signed by the Lessee named in the
              Application Form.
            </span>
          </div>
          <div className="terms_section_sub_heads">
            2. &nbsp;
            <span className="text-underline text-decoration-underline">
              Rental Deposit & Charges
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">2.1.&nbsp;</span>
            <span>
              The Deposit must be paid by You on or before the Start Date.
              Subject to the provisions of these Conditions, the Deposit is
              refundable upon termination or expiry of the Lease Agreement,
              provided You return all debts owed to Us under this Lease
              Agreement have been paid in full.
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">2.2.&nbsp;</span>
            <span>
              The Rent for the first twelve (12) months of the Term shall be as
              set out in the application form. The Rent for the first twelve
              (12) months of the Term must be paid at the same time as the
              Deposit in cleared funds.
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">2.3.&nbsp;</span>
            <span>
              All other charges that may be payable by You at any time, whether
              in relation to additional services or any other charges which You
              may from time to time incur, are as set out in Our Price List from
              time to time. We reserve the right to amend the Price List from
              time to time and without Notice.
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">2.4.&nbsp;</span>
            <span>
              If the Term is more than twelve (12) months or is extended for
              further period of twelve (12) months, the Rent for each additional
              twelve (12) month period will be the Rent then current in the
              Price List. A copy of the Price List current from time to time is
              available from Our office or on Our website. For each twelve (12)
              month period after the initial twelve (12) month period of the
              Term, we will invoice You in advance for the charge due for such
              twelve (12) month period. You must pay such sum or give Us written
              notice of Your intention to terminate this Lease Agreement with
              immediate effect within fourteen (14) days of Your receipt of such
              invoice. Such notice shall be followed by collecting all items
              remaining in the Locker.
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">2.5.&nbsp;</span>
            <span>
              All payments may be made by cash, credit, debit, or online
              transfer. We will not treat any amount as paid until We are in
              receipt of cleared funds for the total amount owed. We will issue
              You with an invoice detailing the lease of the Locker and all
              related services agreed and the amount due to Us.
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">2.6.&nbsp;</span>
            <span>
              All Our prices and charges are quoted in Indian Rupees, and unless
              otherwise stated, are exclusive of applicable taxes at the
              prevailing rate and where applicable.
            </span>
          </div>
          <div className="terms_section_sub_heads">
            3. &nbsp;
            <span className="text-underline text-decoration-underline">
              Late Payment, Non-Payment, and Interest
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">3.1.&nbsp;</span>
            <span>
              Rent and all other charges and/or other payments due under this
              Lease Agreement are to be paid within the period stated in this
              Lease Agreement, these Conditions and/ or the Price List (as the
              case may be) (“Due Date”).
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">3.2.&nbsp;</span>
            <span>
              If You fail to pay any sums due to Us at any time by the Due Date,
              interest shall be charged by Us on the outstanding amount at the
              rate of 18% p.a. from time to time and from the date the payment
              became due until the outstanding sums are paid in full, both
              before as well as after any judgment. Our right to interest is
              without prejudice to any other rights We might have in relation to
              late payment.
            </span>
          </div>
          <div className="terms_section_sub_heads">
            4. &nbsp;
            <span className="text-underline text-decoration-underline">
              Suspension of Services
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">4.1.&nbsp;</span>
            <span>
              In the event of non-payment by the Due Date for payment of the
              Rent or any other charges, We reserve the right to suspend the
              lease of the Locker without further Notice.
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">4.2.&nbsp;</span>
            <span>
              Pursuant to paragraph above:
              <ol type="a" className="custom-ordered-list text-left ps-0">
                <div className="terms d-flex">
                  <span className="me-3">a.&nbsp;</span>
                  <span>We will not allow You access to the Locker; and</span>
                </div>
                <div className="terms d-flex">
                  <span className="me-3">b.&nbsp;</span>
                  <span>
                    All Our liability to You in respect of the contents of the
                    Locker shall cease, until such time as all outstanding
                    amounts shall have been paid to Us in full together with
                    interest.
                  </span>
                </div>
              </ol>
            </span>
          </div>
          <div className="terms_section_sub_heads">
            5. &nbsp;
            <span className="text-underline text-decoration-underline">
              Release & Disposal
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">5.1.&nbsp;</span>
            <span>
              In the event that We have not received payment of all amounts due
              and owing to Us by the expiry of a period (90) ninety days
              following the due date on which the first amount outstanding
              became due under the relevant Lease Agreement, We shall be
              entitled to, having given You not less than (30) thirty days’
              Notice of Our intention to do so, immediately terminate the Lease
              Agreement (termination date) and We shall be released from all
              further obligations to You.
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">5.2.&nbsp;</span>
            <span>
              If You have not cleared Your debt outstanding and due to Us within
              (60) sixty days of the termination date, we shall be entitled to
              break open the Locker and keep the contents of the Locker in a
              sealed cover in the presence of independent witnesses as
              designated by us, whose signature of an inventory of the content
              of the locker shall be conclusive evidence.
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">5.3.&nbsp;</span>
            <span>
              In the event that You or Your Nominee or Your representatives do
              not claim the contents of the sealed cover within a period of (3)
              three years from the date of breaking open the Locker, We shall be
              entitled to sell the contents by way of a public auction and You
              hereby unconditionally agree and authorize such an auction. We
              shall be entitled to appropriate net sale proceeds (after meeting
              expenses for sale) towards Rent and other outstanding dues of
              locker and expenses incurred by Us in breaking open the Locker.
            </span>
          </div>
          <div className="terms_section_sub_heads">
            6. &nbsp;
            <span className="text-underline text-decoration-underline">
              Registration of Biometrics & Face ID
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">6.1.&nbsp;</span>
            <span>
              When You apply for a Locker with Us, You i.e. the primary account
              holder and joint holder will be registered with their respective
              biometrics and face IDs so that the access is restricted to the
              registered biometrics and face recognitions of the primary holder
              and joint holder for the respective Locker(s) only.
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">6.2.&nbsp;</span>
            <span>
              We require You to register the biometrics and face ID which will
              be used to identify and verify access at the vault door. You will
              be granted access to the Locker only once the biometric and face
              recognition access matches the registered biometrics and face IDs.
            </span>
          </div>
          <div className="terms_section_sub_heads">
            7. &nbsp;
            <span className="text-underline text-decoration-underline">
              Contents of the Locker
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">7.1.&nbsp;</span>
            <span>
              You acknowledge that We are not aware of the contents of the
              Locker and have no knowledge as to the purpose of the storage or
              the use to which the contents of the Locker may be put at any
              time.
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">7.2.&nbsp;</span>
            <span>
              The Locker is designed to store papers, valuables, and other items
              of a similar nature for lawful purposes only. By entering into
              this Lease Agreement, You agree that You will not store or allow
              any other person to store anything that is illegal, offensive,
              immoral, obscene, indecent, defamatory, slanderous, libelous,
              noxious, poisonous, corrosive, inflammable, explosive or unstable,
              nor any guns, knives, firearms, ammunition, chemicals, drugs,
              plants or plant materials, nor any living organism or any other
              substance or material which may be the subject of any ban, embargo
              or import restriction, nor any proceeds of prostitution or drug
              trafficking or the proceeds of any other crime, nor anything which
              is otherwise unlawful or which has or may be used in any act of
              terrorism or which will or may cause any harm whatsoever to any
              person, premises or place, including (without limitation) the
              Locker or Our premises, employees, agents, contractors, customers
              or visitors.
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">7.3.&nbsp;</span>
            <span>
              You agree to fully indemnify Us (which means You must fully
              compensate Us for) and pay Us for all costs, charges, expenses,
              claims or damages that We incur or which are made against Us in
              the event of any breach by You of this Condition 8.2 in respect of
              any harm, damage or loss that We or any of Our employees, agents,
              Lessees, contractors, customers or visitors to Our premises
              suffer.
            </span>
          </div>
          <div className="terms_section_sub_heads">
            8. &nbsp;
            <span className="text-underline text-decoration-underline">
              Term, Termination & Refund
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">8.1.&nbsp;</span>
            <span>
              This Lease Agreement will commence on the Start Date and will
              continue and, unless You give Us Notice to renew or extend the
              Lease Agreement, it shall expire at the end of the Term unless
              terminated earlier.
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">8.2.&nbsp;</span>
            <span>
              You may terminate this Agreement at any time by (14) fourteen days
              prior notice in writing to Us, subject always to collecting all
              items remaining in the Locker. We will refund to You the Deposit,
              less any deductions which We are entitled to make in accordance
              with these Conditions but will be liable to refund the Rent for
              the unexpired Term.
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">8.3.&nbsp;</span>
            <span>
              We may agree to an early termination request from You, whereupon
              we would require You to collect all items remaining in the Locker.
              You would also be required to confirm that You have removed the
              contents of the Locker. Any debts owed to Us must be discharged at
              such time. The Deposit amount would be returned to You within (30)
              thirty days of Our acceptance of Your termination request, subject
              to deductions of any amount owed to Us. In such event, we will not
              be liable to You for refund of the Rent in respect of the Locker
              for any residual period.
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">8.4.&nbsp;</span>
            <span>
              We may terminate the Lease Agreement:
              <ol type="a" className="custom-ordered-list text-left ps-0">
                <div className="terms d-flex">
                  <span className="me-3">a.&nbsp;</span>
                  <span>
                    With immediate effect, by way of written Notice to You if We
                    become aware of or reasonably suspect any breach of the
                    terms of paragraph 8, or the provisions of any other
                    paragraph(s) within the terms of this agreement.
                  </span>
                </div>
                <div className="terms d-flex">
                  <span className="me-3">b.&nbsp;</span>
                  <span>
                    In the event of a material breach by You of any of Our Terms
                    which has not been remedied by You within (30) thirty days
                    of Our Notice to You in writing specifying the nature of the
                    breach and the remedy required.
                  </span>
                </div>
                <div className="terms d-flex">
                  <span className="me-3">c.&nbsp;</span>
                  <span>
                    In the event You are unable to pay Your dues by the Due Date
                    and the Company suspends the lease of the Locker under
                    paragraph 4.
                  </span>
                </div>
                <div className="terms d-flex">
                  <span className="me-3">d.&nbsp;</span>
                  <span>
                    We may terminate the Lease Agreement immediately if a
                    petition in bankruptcy is presented against You or You are
                    declared bankrupt or similar proceedings are taken in
                    respect of a business or corporate body.
                  </span>
                </div>
                <div className="terms d-flex">
                  <span className="me-3">e.&nbsp;</span>
                  <span>In accordance with Release & Disposal section.</span>
                </div>
              </ol>
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">8.5.&nbsp;</span>
            <span>
              At the end of the Term
              <ol type="a" className="custom-ordered-list text-left ps-0">
                <div className="terms d-flex">
                  <span className="me-3">a.&nbsp;</span>
                  <span>
                    All outstanding debts due to Us and remaining outstanding,
                    must be paid immediately.
                  </span>
                </div>
                <div className="terms d-flex">
                  <span className="me-3">b.&nbsp;</span>
                  <span>
                    You must immediately remove all contents from the Locker.
                  </span>
                </div>
              </ol>
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">8.6.&nbsp;</span>
            <span>
              If, You do not remove all the contents of the Locker:
              <ol type="a" className="custom-ordered-list text-left ps-0">
                <div className="terms d-flex">
                  <span className="me-3">a.&nbsp;</span>
                  <span>
                    You shall be liable for and shall pay to Us on demand all
                    relevant charges set out in Our Price List under additional
                    charges, together with all costs and expenses which We may
                    reasonably incur.
                  </span>
                </div>
                <div className="terms d-flex">
                  <span className="me-3">b.&nbsp;</span>
                  <span>
                    You will also continue to be liable for the applicable Rent
                    in respect of the Locker, current at time of the event, as
                    though the period of hire of the Locker was renewed for a
                    further minimum Lease Agreement Term.
                  </span>
                </div>
                <div className="terms d-flex">
                  <span className="me-3">c.&nbsp;</span>
                  <span>
                    If the above requirements have not been met by You following
                    a period of (60) sixty days after the end of the Term, we
                    shall be entitled to take all the steps detailed in Release
                    & Disposal Section.
                  </span>
                </div>
              </ol>
            </span>
          </div>
          <div className="terms_section_sub_heads">
            9. &nbsp;
            <span className="text-underline text-decoration-underline">
              Limitation of Liability
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">9.1.&nbsp;</span>
            <span>
              While the Company will exercise all such normal precautions as it
              may in its own discretion deem fit, Company will not be
              responsible for any loss or deterioration of or damage or loss to
              articles in locker whether caused by act that is attributable to
              the fault or negligence from You whatsoever or fire, flood,
              earthquake, lightening, act of war, civil disorder or any other
              similar cause(s) beyond the reasonable control of the Company and/
              or force majeure events
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">9.2.&nbsp;</span>
            <span>
              Notwithstanding any other provision of this Lease Agreement, the
              maximum liability of the Company for any breach or violation is
              limited to one hundred times the prevailing annual rent of the
              Locker.
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">9.3.&nbsp;</span>
            <span>
              Death or mental capacity of Lessee (Personal Account): If You are
              a private individual and You die or become mentally incapable
              during the Term, We shall allow Your personal representatives or
              lawfully appointed attorney to open the Locker, or shall open the
              Locker for Your personal representatives or attorney provided that
              such persons produce to Us on demand a valid appointment as
              attorney, grant of probate or letters of administration appointing
              such person as the executors or administrators of Your estate, and
              such persons produce to Us, on demand, a death certificate &
              evidence of their identity and enter into an indemnity in Our
              favour in a form satisfactory to Us.
            </span>
          </div>
          <div className="terms_section_sub_heads">
            10. &nbsp;
            <span className="text-underline text-decoration-underline">
              General
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">10.1.&nbsp;</span>
            <span>
              You acknowledge that Your rights regarding the Locker are those of
              a lessee and nothing in these Conditions grants You any right to
              ownership in the Locker or the premises in which it is stored or
              otherwise kept. You must neither assign, sublet nor transfer any
              interest in the locker or any part of it.
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">10.2.&nbsp;</span>
            <span>
              If a court or any other competent authority finds that any
              provision of this Lease Agreement and/or these Conditions (or part
              of any provision) is invalid, illegal, or unenforceable, that
              provision or part-provision shall, to the extent required, be
              deemed deleted, and the validity and enforceability of the other
              provisions of this Lease Agreement and/or these Conditions shall
              not be affected.
            </span>
          </div>
          <div className="terms_text mb-2 d-flex">
            <span className="me-2">10.3.&nbsp;</span>
            <span>
              This Agreement is made subject to Indian law and all matters
              arising out of it shall be subject to the jurisdiction of courts
              at the place where the locker facility is availed.
            </span>
          </div>
        </div>
      </div>
      {!hideFooter && <div className="Terms_footer">
        <FooterComponent />
      </div>}
    </div>
  );
}

export default TermsAndConditionsComponent;
