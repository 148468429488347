import React from "react";
import "../assets/css/partner_with_us.css";

/**
  PartnerWithUs Component lists the partners with us
*/
function PartnerWithUsComponent() {
  return (
    <section id="partner_with_us_section">
      <div className="container-fluid" id="partner_container">
        <div id="partner_background">
          <img
            src="https://pub-assets.aurm.in/Partner_with_us_section_images/Partner_Background_Box.png"
            alt="partner with us"
            className="w-100 partner_bg_img"
            id="partner_bg_img"
            loading="lazy"
          />
          <img
            src="https://pub-assets.aurm.in/Partner_with_us_section_images/mobile_partner_background_box.png"
            alt="partner with us"
            className="d-none w-100 partner_bg_img"
            id="mobile_partner_bg_img"
            loading="lazy"
          />
        </div>
        <div className="text-center w-100" id="partner_content">
          <div className="m-auto container text-center" id="header_part">
            <div id="partner_heading">Partner with us</div>
            <div id="partner_sub_text" className="mb-3">
            Join hands with Aurm to unlock a world of 
            collaborative opportunities. 
            </div>
          </div>
          <div className="container col-md-12" id="partner_cards_div">
            <div className="row m-auto justify-content-center">
              <div className="col-md-3 partner_cards">
                <div className="card text-center bg-transparent border-0 position-relative">
                  <div className="m-auto">
                  <picture>
                    <source srcSet="https://pub-assets.aurm.in/Partner_with_us_section_images%2Frealestate.webp" type="image/webp" />
                    <img src="https://pub-assets.aurm.in/partner_with_us/realestate.svg" alt="realestate" width='100%' loading="lazy" />
                  </picture>
                  </div>
                  <div className="card_text">Real Estate Companies</div>
                </div>
              </div>
              <div className="col-md-3 partner_cards">
                <div className="card text-center bg-transparent border-0 position-relative">
                  <div className="m-auto">
                    <picture>
                      <source srcSet="https://pub-assets.aurm.in/Partner_with_us_section_images%2Fbank.webp" type="image/webp" />
                      <img src="https://pub-assets.aurm.in/partner_with_us/bank.svg" alt="bank" width='100%' loading="lazy" />
                    </picture>
                  </div>
                  <div className="card_text">Banks & Insurance</div>
                </div>
              </div>
              <div className="col-md-3 partner_cards">
                <div className="card text-center bg-transparent border-0 position-relative">
                  <div className="m-auto">
                    <picture>
                      <source srcSet="https://pub-assets.aurm.in/Partner_with_us_section_images%2Fhousing.webp" type="image/webp" />
                      <img src="https://pub-assets.aurm.in/partner_with_us/housing.svg" alt="housing" width='100%' loading="lazy" />
                    </picture>
                  </div>
                  <div className="card_text">Housing Societies</div>
                </div>
              </div>
              <div className="col-md-3 partner_cards">
                <div className="card text-center bg-transparent border-0 position-relative">
                  <div className="m-auto">
                    <picture>
                      <source srcSet="https://pub-assets.aurm.in/Partner_with_us_section_images%2Fadvertisers.webp" type="image/webp" />
                      <img src="https://pub-assets.aurm.in/partner_with_us/advertisers.svg" alt="advertisers" width='100%' loading="lazy" />
                      </picture>
                  </div>
                  <div className="card_text">Jewellers</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PartnerWithUsComponent;
