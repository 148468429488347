
import React, { useEffect, useRef, useState } from "react";
import HeaderComponent from "./HeaderComponent";
import "../assets/css/landing.css";
import dot from "../assets/images/dot.svg";
import video_mask from "../assets/images/landing_video_mask_bottom.png";

/**
  Landing component shows the Landing Page with Video
*/
function LandingComponent() {
  const videoRef = useRef(null);
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    const landingVideo = videoRef.current;
    if (!landingVideo) return;
    const handleTimeUpdate = () => {
      if (landingVideo.currentTime >= 10 && !showText) {
        setShowText(true);
      }
      const isEndOfLoop = landingVideo.currentTime < 10 && showText;
      if (isEndOfLoop) {
        setShowText(true);
      }
      if (landingVideo.currentTime === 0) {
        setShowText(true);
      }
    };
    
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        landingVideo.play();
      } else {
        landingVideo.pause();
      }
      if (landingVideo.currentTime >= 10 && !showText) {
        setShowText(true);
      }
      if (landingVideo.currentTime === 0) {
        setShowText(true);
      }
    };
    landingVideo.addEventListener('timeupdate', handleTimeUpdate);
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      landingVideo.removeEventListener('timeupdate', handleTimeUpdate);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [showText]);
  
  return (
    <div id="landing_section">
      <HeaderComponent />
      <div id="lading_Section_for_mobile">
      <div className="container-fluid p-0" id="video_container">
      <video
        id="landing_video"
        poster="https://pub-assets.aurm.in/Poster_Image_for_Video.webp"
        autoPlay
        muted
        loop
        playsInline
        preload="metadata"
        ref={videoRef}
        loading="lazy"
      >
        <source
          src="https://pub-assets.aurm.in/aurm_locker_animation_loop.mp4"
          type="video/mp4"
          loading="lazy"
        />
        Your browser does not support the video tag.
      </video>
      </div>
      <div id="tinted_overlay">
      {showText && (
       
          <div className="container landing_overlay_text text-center">
            Introducing Aurm. <br />
            Safe Deposit Vaults, Re-imagined.
            <div className="landing_subtext">
              Bringing the future of secure storage to your residential community
            </div>
          </div>
      )}
      </div>
      <div id="video_bottom_img_div" className="bg-white">
              <img src={video_mask} id="video_mask_img" alt="button_back"/>
            </div>
      <div className="container d-flex justify-content-center touch_btn_div position-absolute bg-transparent">
          <img src={dot} alt="dot" />
          <div>
            <a rel="noreferrer" href="https://api.whatsapp.com/send/?phone=919740741016&text=I'm intrigued about Aurm. Tell me more! &type=phone_number&app_absent=0" target="_blank">
              <button
                className="btn btn-primary rounded_pink_btn pt-2 pb-2"
                id="get_in_touch_btn"
              >
                Get in touch
              </button>
            </a>
          </div>
          <img src={dot} alt="dot" />
        </div>
      </div>
    </div>
  );
}

export default LandingComponent;
